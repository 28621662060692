import React from 'react';
import PropTypes from 'prop-types';

const Modal = ({ component, onClose, metadata: { title } }) => {
  return (
    <div className="modal">
      <div className="modal-header">
        {title}
      </div>
      <div className="modal-body">
        {component}
      </div>
    </div>
  );
};

export default Modal;

Modal.propTypes = {
  component: PropTypes.node,
  onClose: PropTypes.func,
  metadata: PropTypes.object,
};
