import React from 'react';

const PrivacyPolicy = () => {
  return(
    <div className="policy-info">
      <h1 className="policy-info-header"> iONclassic Privacy Policy</h1>
      <p>Date of Last Revision: Sept 178 2019</p>
      <p>
        iONclassic, LLC. and its subsidiaries, including iONclassic (“iONclassic LLC”, “we” or “us”),
        are passionately committed to the privacy of our customers. This Privacy Policy (“Privacy Policy”)
        explains how information about you is collected, used and disclosed by iONclassic LLC. This
        Privacy Policy applies to information we collect when you use the iONclassic services, including
        the iONclassic mobile application and iONclassic website (collectively, the “Services”). This Privacy
        Policy applies only to the services offered by the iONclassic division of iONclassic LLC
        (“iONclassic”) and do not apply to any other services offered by iONclassic LLC. Please see
        https://www.ionclassic.com/about/privacy for the privacy policy applicable to those services.
        We may change this Privacy Policy from time to time. If we make changes, we will notify you by
        revising the date at the top of the policy and, in some cases, we may provide you with additional
        notice (such as adding a statement to our homepage or sending you an email notification). We
        encourage you to review the Privacy Policy whenever you access the Services to stay informed
        about our information practices and the choices available to you.
      </p>
      <h2 className="policy-info-section-header">Collection of Information</h2>
      <h3 className="policy-info-section-sub-header">Information You Provide to Us</h3>
      <p>We collect information you provide directly to us. For example, we collect information when you:</p>
      <ul>
        <li>Create an account and subscribe to our digital photography management and storage services, media hosting or other services;</li>
        <li>Purchase products or services;</li>
        <li>Participate in a survey, contest or sweepstakes;</li>
        <li>Contact us for any reason, such as to request help with the Services or to provide us with your comments/feedback; or</li>
        <li>Post information in connection with your photos and galleries (e.g., in a photo caption) or in other forums or interactive areas of the Services.</li>
      </ul>
      <p>
        The types of information we may collect include your name, email address, postal address, phone number,
        vehicle information, credit card information and other information you choose to provide.
      </p>
      <h3 className="policy-info-section-sub-header">Information We Collect Automatically When You Use the Services</h3>
      <p>When you access or use our Services, we automatically collect information about your use of the Services, including:</p>
      <ul>
        <li>Log Information:</li>
      </ul>
      <p>
        We log information about your use of the Services, including the type of browser you use, access times, pages viewed, your
        IP address and the page you visited before navigating to our Services.
      </p>
      <ul>
        <li>Device Information:</li>
      </ul>
      <p>
        We collect information about the computer or mobile device you use to access our Services, including the hardware model, operating system
        and version, screen resolution, color and depth, device identifiers and mobile network information.
      </p>
      <ul>
        <li>Location Information:</li>
      </ul>
      <p>
        With your consent, we may collect information about the location of your device. For example, we use your location information to provide you
        with the option to add POI data to your photo. For more details, please see "Your Choices" below.
      </p>
      <ul>
        <li>Photo Location Information:</li>
      </ul>
      <p>When you upload a photo with geographical data (i.e. from a mobile device) or manually geotag your photo, we collect the location of that photo. With your consent, we collect information about your location if you take a photo within the iONclassic mobile application to add to your photo’s metadata.</p>
      
      <h3 className="policy-info-section-sub-header">Information We Collect From Other Sources</h3>
      <p>We may also obtain information from other sources and combine that with information we collect through our Services. For example, if you create or log into your account through a social media site, we will have access to certain information from that site, such as your name, account information and friends lists, in accordance with the authorization procedures determined by such social media site. We may receive updated information about you, such as an updated billing address, from the financial institution issuing your credit card in connection with our billing for the Services.</p>
      <h3 className="policy-info-section-sub-header">Use of Information</h3>
      <p>We may use information we collect to provide and improve our Services, to deliver the products and services you order, to facilitate contests, and to communicate with you. We also use the information we collect to understand how users interact with our services and to personalize our Services.</p>
      <p className="policy-info-section-sub-header">Sharing of Information</p>
      <p>We may share information about you as follows or as otherwise described in this Privacy Policy:</p>
      <ul>
        <li>With vendors, consultants and other service providers who need access to such information to carry out work on our behalf;</li>
        <li>In response to a request for information if we believe disclosure is in accordance with any applicable law, regulation or legal process, or as otherwise required by any applicable law, rule or regulation;</li>
        <li>If we believe your actions are inconsistent with the spirit or language of our user agreements or policies, or to protect the rights, property and safety of iONclassic LLC or others;</li>
        <li>In connection with, or during negotiations of, any merger, sale of company assets, financing or acquisition of all or a portion of our business to another company; and</li>
        <li>With your consent or at your direction, including if we notify you through our Services that the information you provide will be shared in a particular manner and you provide such information.</li>
      </ul>
      <p>We may also share aggregated information that does not directly identify you.</p>
      <h3 className="policy-info-section-sub-header">Social Sharing Features and Third-Party Integrations</h3>
      <p>The Services may offer social sharing features and other integrated tools which let you share actions you take on our Services with other media, and vice versa. The use of such features enables the sharing of information with your friends or the public, depending on the settings you establish with the entity that provides the social sharing feature. For more information about the purpose and scope of data collection and processing in connection with social sharing features, please visit the privacy policies of the entities that provide these features.</p>
      <h3 className="policy-info-section-sub-header">Advertising and Analytics Services Provided by Others</h3>
      <p>We may allow others to serve advertisements on our behalf across the Internet and to provide analytics services. These entities may use cookies, web beacons, mobile device identifiers, and other technologies to collect information about your use of the Services and other websites, including your IP address, web browser, pages viewed, time spent on pages, links clicked and conversion information. This information may be used by iONclassic LLC and others to, among other things, analyze and track data, determine the popularity of certain content, deliver advertising and content targeted to your interests on our Services and other websites and better understand your online activity. Your device may also include a feature (“Limit Ad Tracking” on iOS or “Opt Out of Interest-Based Ads” or “Opt Out of Ads Personalization” on Android) that allows you to opt out of having certain information collected through apps used for interest-based advertising purposes.</p>
      <h3 className="policy-info-section-sub-header">Security</h3>
      <p>iONclassic LLC and iONclassic take reasonable measures to help protect information about you from loss, theft, misuse and unauthorized access, disclosure, alteration and destruction.</p>
      <h3 className="policy-info-section-sub-header">Data Retention</h3>
      <p>We store the information we collect about you for as long as is necessary for the purpose(s) for which we originally collected it. We may retain certain information for legitimate business purposes or as required by law.</p>
      <h3 className="policy-info-section-sub-header">Data Transfers</h3>
      <p>To provide the Services, we may transfer your information to iONclassic LLC servers in the United States. </p>
      <h3 className="policy-info-section-sub-header">Account Information</h3>
      <p>You may update, correct or delete information about you at any time by logging into your online account or emailing us at help@ionclassic.com. You may delete your account or terminate your subscription pursuant to iONclassic’s Terms of Use, but note that we may retain certain information as required by law or for legitimate business purposes.</p>
      <h3 className="policy-info-section-sub-header">Privacy and Permissions Settings</h3>
      <p>In your account “Privacy & Permissions” tab under “Settings”, you can control who is able to see different parts of your profile, including email address, IM names, real name, and current city. If you choose to use your real name (or include any identifying information as part of your “screen name”) that information will be publicly displayed through the Services. Therefore, please use caution in determining how you wish to be identified in using the Services.</p>
      <h3 className="policy-info-section-sub-header">Deleting Photos</h3>
      <p>You can delete individual photos that you have on iONclassic by selecting the photos that you wish to delete, then clicking the delete button. Or you can delete groups of photos using batch operations.</p>
      <h3 className="policy-info-section-sub-header">Photo Sharing Features</h3>
      <p>In your “Privacy & Permissions” setting, you can choose to make your photos default to public for anyone to access, restrict access to a limited number of other iONclassic users, or keep those photos private so only you can access them. If you do not change your settings, your photos will be public by default. </p>
      <h3 className="policy-info-section-sub-header">Location Information</h3>
      <p>When you first launch any of our mobile applications that collect location information, you will be asked to consent to the application’s collection of this information. If you initially consent to our collection of location information, you can subsequently stop the collection of this information at any time by changing the preferences on your mobile device.</p>
      <h3 className="policy-info-section-sub-header">Cookies</h3>
      <p>Most web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove or reject browser cookies. Removing or rejecting browser cookies does not necessarily affect third party flash cookies used in connection with our Services. To delete or disable flash cookies please visit www.adobe.com/products/flashplayer/security for more information. Please note that if you choose to remove or reject cookies, this could affect the availability and functionality of our Services.</p>
      <h3 className="policy-info-section-sub-header">Promotional Communications</h3>
      <p>You may opt out of receiving promotional communications from iONclassic by following the instructions in those communications or by emailing us at help@ iONclassic.com. If you opt out, we may still send you non-promotional communications, such as those about your account or our ongoing business relations.</p>
      <h3 className="policy-info-section-sub-header">Email Notifications</h3>
      <p>Visit your iONclassic account information area to edit your iONclassic email notification settings.</p>
      <h3 className="policy-info-section-sub-header">Legal Basis for Processing</h3>
      <p>If you are a resident of the EEA, when we process your personal data we will only do so in the following situations:</p>
      <ul>
        <li>We need to use your personal information to perform our responsibilities under our contract with you (e.g., processing payments for and providing the Services you have requested).</li>
        <li>We have a legitimate interest in processing your personal data. For example, we may process your personal data to send you marketing communications, to communicate with you about changes to our Services, and to provide, secure, and improve our Services.</li>
      </ul>
      <h3 className="policy-info-section-sub-header">Data Subject Requests</h3>
      <p>If you are a resident of the EEA, you have the right to access personal information we hold about you and to ask that your personal information be corrected, erased, or transferred. You may also have the right to object to, or request that we restrict, certain processing. If you would like to exercise any of these rights, you can log into your account and use the settings and features explained above or you may contact us as indicated below.</p>
      <h3 className="policy-info-section-sub-header">Questions or Complaints</h3>
      <p>If you are a resident of the EEA and have a concern about our processing of personal information that we are not able to resolve, you have the right to lodge a complaint with the data privacy authority where you reside. For contact details of your local Data Protection Authority, please see: http://ec.europa.eu/justice/data-protection/article-29/structure/data-protection-authorities/index_en.htm.</p>
      <h3 className="policy-info-section-sub-header">Contact Us</h3>
      <p>We welcome your feedback. If you have any comments or concerns about this Privacy Policy or would like to contact us for any reason, you may reach us via email at help@ionclassic.com</p>
    </div>
  )
}

export default PrivacyPolicy;
