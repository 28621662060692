import React from "react";
import MissingCarMainTransparent from "../../assets/images/fallback-images/missing-car-main-transparent";
import Button from "../shared/buttons/Button";

const RestorationListItem = ({
  id,
  make,
  model,
  year,
  miles,
  heroImage,
  qrCodeDocUrl,
  owner,
  onClick,
  history,
}) => {
  return (
    <div className="restoration">
      <div className="restoration-row">
        <div className="column first">
          <div className="info">{year ? year : ""}</div>
          <div className="info">{make ? make : ""}</div>
          <div className="info">{model ? model : ""}</div>
          <div className="info">{miles ? miles : ""}</div>
        </div>
        <div className="column second">
          {heroImage ? (
            <img
              src={heroImage}
              alt={`${make ? make : ""} ${model ? model : ""}`}
            />
          ) : (
            <div className="missing-car-container">
              <div className="missing-car-icon">
                <MissingCarMainTransparent />
              </div>
            </div>
          )}
        </div>
        {/* <div className='column owner'>
        <div>
          <div className='header'>{owner && owner.firstName ? owner.firstName : ''} {owner && owner.lastName ? owner.lastName : ''}</div>
          <div className='info'>{owner && owner.phoneNumbers[0] ? owner.phoneNumbers[0].number : ''}</div>
          <div className='info'>{owner && owner.email ? owner.email : ''}</div>
        </div>
      </div> */}
        <div className="column detail-buttons">
          <Button
            text="DETAILS"
            onClick={() => history.push(`/dashboard/restorations/${id}`)}
          />
          <Button
            text="PHOTOS"
            onClick={() => history.push(`/dashboard/restorations/photos/${id}`)}
          />
          <Button
            text="SLIDESHOW"
            onClick={() =>
              history.push(`/dashboard/restorations/slide-show/${id}`)
            }
          />
        </div>
        <div className="column detail-buttons">
          <Button
            text="QR CODE"
            onClick={() =>
              qrCodeDocUrl
                ? window.open(qrCodeDocUrl, "_blank")
                : alert("Invalid qr code")
            }
          />
        </div>
      </div>
    </div>
  );
};

export default RestorationListItem;
