import React, { Fragment, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';

const required = value => (value ? undefined : 'Required');

const ShopSignUpForm = ({ history, modalState, setModalState, setAuthToken }) => {
  const [registerError, setRegisterError] = useState('');
  const [spinner, setSpinner] = useState(false);
  const [createManager] = useMutation(CREATE_MANAGER);
  const [fetchCurrentUser, currentUserData] = useLazyQuery(GET_CURRENT_USER);


  const onSubmit = (values) => {
      setSpinner(true);
      createManager({ variables: {
        input: {
          username: values.email,
          password: values.password,
          managerType: 'owner',
        }
      }}).then(response => {
        setAuthToken(true);
        localStorage.setItem('ionToken', response.data.createManager.token)
      }).then( () => {
        setSpinner('false');
        history.push('/get-started');
      }).catch(err => {
        let regex = /"(.*)"/g
        let error = err.message.match(regex);
        setRegisterError(error[0].replace(/"/g,""));
        setSpinner(false);
      })
    }

  if (currentUserData && currentUserData.data ) {

  }
  return(
    <div className="login-form">
          <div className="login-form-header">SIGN UP</div>
          <Form
            onSubmit={onSubmit}
            render={({ handleSubmit, form, submitting, pristine, values }) => (
              <form onSubmit={handleSubmit}>
                <div className="login-form-input">
                  <Field name="email" validate={required}>
                    {({ input, meta }) => (
                      <Fragment>
                        <label className="login-form-input-label">EMAIL</label>
                        {meta.error && meta.touched && <div className="login-form-input-error">{meta.error}</div>}
                        <input {...input} type="text" placeholder="User Name" />
                      </Fragment>
                    )}
                  </Field>
                </div>
                <div className="login-form-input">
                  <Field name="password" validate={required}>
                    {({ input, meta }) => (
                      <Fragment>
                        <label className="login-form-input-label">PASSWORD (6 or more characters)</label>
                        {meta.error && meta.touched && <div className="login-form-input-error">{meta.error}</div>}
                        <input {...input} type="password" placeholder="Password" id="password" autoComplete={'new-password'} />

                      </Fragment>
                    )}
                  </Field>
                </div>
                {registerError ? <div className="login-form-error">{registerError}</div> : null}
                {currentUserData && currentUserData.loading ?
                  <div className="spinner">
                    <div className="dot1"></div>
                    <div className="dot2"></div>
                  </div>
                :
                  <div className="login-form-button">
                    <button className="login-form-input-button">JOIN NOW</button>
                  </div>
                }
                {spinner ?
                  <div className="spinner">
                    <div className="dot1"></div>
                    <div className="dot2"></div>
                  </div> : null
                }
                  <div className="login-form-text">
                    <>Already have an account? <a className="signup-link" href={'/shop-sign-in'} >Sign in</a></>
                  </div>
              </form>
            )}
          />
    </div>
  )
}


export default ShopSignUpForm;

const CREATE_MANAGER = gql`
  mutation createManager( $input: CreateManagerInput!) {
    createManager(input: $input) {
      token
    }
  }
`;

const GET_CURRENT_USER = gql`
  {
    currentUser {
      subscriptionActive
    }
  }
`;

