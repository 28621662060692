import React from 'react';

const CancelSubModal = ({ setCancelSubModal, cancelSub, history }) => {

  function cancelSubscription() {
    cancelSub().then(() => {
      history.push('/get-started')
    })
  }

  return(
    <div className="cancel-sub">
      <div className="cancel-sub-modal">
        <div className="cancel-sub-header">Are you sure you want to cancel your subscription?</div>
        <div className="cancel-sub-buttons">
          <div className="cancel-sub-buttons-button" onClick={() => cancelSubscription()} >Yes</div>
          <div className="cancel-sub-buttons-button" onClick={() => setCancelSubModal(false)}>No</div>
        </div>
      </div>
    </div>
  )
}

export default CancelSubModal;
