import React from "react";

import IconFB from "../../assets/images/FACEBOOK.png";
import IconInsta from "../../assets/images/INSTAGRAM.png";
import IconTwitter from "../../assets/images/TWITTER.png";

const Footer = () => {
  const contactSection = {
    header: "CONTACT US",
    links: [
      {
        text: "(813) 576-9335",
        url: "tel:+1-813-576-9335",
      },
      {
        text: "info@ionclassic.com",
        url: "mailto:info@ionclassic.com",
      },
    ],
  };

  const servicesSection = {
    header: "SERVICES",
    links: [
      {
        text: "Advertise",
        url: "advertiseurl",
      },
      {
        text: "Work With US",
        url: "workwithusurl",
      },
      {
        text: "FAQ",
        url: "https://ionclassic.com/faq",
      },
    ],
  };

  const informationSection = {
    header: "INFORMATION",
    links: [
      {
        text: "Privacy Policy",
        url: "https://ionclassic.com/privacy-policy",
      },
      {
        text: "Terms & Conditions",
        url: "https://ionclassic.com/terms-and-conditions",
      },
      {
        text: "Press Inquiries",
        url: "url",
      },
    ],
  };
  const footerSections = [contactSection, servicesSection, informationSection];

  return (
    <div className="footer">
      <div className="footer-row">
        {footerSections.map((section) => (
            <div className="footer-section" key={section.header}>
              <div className="section-header">{section.header}</div>
              {section.links.map((link) => (
                <div className="links" key={link.text}>
                  <a href={link.url}>{link.text}</a>
                </div>
              ))}
            </div>
        ))}
        <div className="footer-section">
          <div className="social-media">
            <img src={IconInsta} alt="Instagram Logo"/>
            <img src={IconTwitter} alt="Twitter Logo" />
            <img src={IconFB} alt="Facebook Logo" />
          </div>
          <div className="copyright">&copy; ION 2019</div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
