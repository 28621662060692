import React from 'react';

import LoginPageShop from './LoginPageShop';
import ShopLogin from "./ShopLogin/ShopLogin";

const LoginPageShopContainer = ({history, setModalState, setAuthToken}) => {
  return(
    <div className='landing-page'>
      <ShopLogin history={history}  setAuthToken={setAuthToken} />
      <LoginPageShop history={history} setModalState={setModalState} />

    </div>
  )
}

export default LoginPageShopContainer;
