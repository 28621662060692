import React from "react";
import PropTypes from "prop-types";
import Button from "../buttons/Button";
import CheckIcon from '../../../assets/images/icons/check';

const ImageUploadedAddModal = ({ onClose }) => {
  return (
    <div className="uploading-image-modal">
      <div className="check-icon"><CheckIcon /></div>
      <div className="uploading-image-header header">Image Uploaded</div>
      <div className="button-row one-button">
        <Button text="Return to Car Profile" onClick={(e) => {
          e.preventDefault();
          onClose();
        }} />
      </div>
    </div>
  );
};

ImageUploadedAddModal.propTypes = {
  onClose: PropTypes.func,
};

export default ImageUploadedAddModal;
