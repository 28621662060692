import React from 'react';

const WelcomeInviteModal = ({ setWelcomeModal }) => {
  return(
    <div className="invite-modal-container">
      <div className="invite-modal">
        <div className="invite-modal-header">CONGRATULATIONS!</div>
        <div className="invite-modal-text">You are signed up.</div>
        <div className="invite-modal-text">Invite your employees to join your iON service.</div>
        <div className="invite-modal-button" onClick={() => setWelcomeModal(false)}>OK</div>
      </div>

    </div>
  )
}

export default WelcomeInviteModal;
