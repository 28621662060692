import React from 'react';

import LandingHeader from './LandingHeader';

import './styles.scss';
import PasswordReset from "./PasswordReset/PasswordReset";

const PasswordResetPageContainer = ({ history, modalState, setModalState, setAuthToken }) => {
  return(
    <div className="landing-owner">
      <LandingHeader history={history} setModalState={setModalState} />
      <PasswordReset history={history} setModalState={setModalState} modalState={modalState} setAuthToken={setAuthToken} />
    </div>
  )
}

export default PasswordResetPageContainer;
