import React from 'react';

import LandingButton from '../shared/buttons/LandingButton';

const LoginPageShop = ({history, setModalState}) => {
  return(
    <div className='landing-header'>
      <div className="landing-header-content">
      </div>
    </div>
  )
}

export default LoginPageShop;
