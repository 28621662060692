import React, { useState, useContext } from "react";
import { Form, Field } from "react-final-form";
import createDecorator from "final-form-focus";

import CompanyProfileFormField from "../CompanyProfile/CompanyProfileFormField";
import MissingCarMainTransparent from "./../../assets/images/fallback-images/missing-car-main-transparent";
import GreenAddIcon from "../../assets/images/icons/green-add-icon";
import RedDeleteIcon from "../../assets/images/icons/red-delete-icon";
import { ModalContext } from "../../services/Context/Context";
import Button from "../shared/buttons/Button";

const focusOnError = createDecorator();

const required = (value) => (value ? undefined : "Required");

const RestorationDetailsForm = ({
  onSubmit,
  onCancel,
  carDetails,
  carDetailsArray,
  newCar,
  assignOwner,
  assignOwnerError,
  assignOwnerSuccess,
  assignOwnerLoading,
  updateCarData,
  deleteCarHeroImage,
  saveHeroImage,
  submitError,
}) => {
  const [fieldURL, setFieldURL] = useState();
  const { modalDispatch } = useContext(ModalContext);

  const fieldChange = (onChange, e) => {
    e.persist();
    if (e.target.files.length === 0) {
      return;
    } else {
      setFieldURL(URL.createObjectURL(e.target.files[0]));
    }
    let files = e.target.files;
    // Process each file
    var allFiles = [];
    for (var i = 0; i < files.length; i++) {
      let file = files[i];
      // Make new FileReader
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let fileInfo = {
          name: file.name,
          type: file.type,
          size: Math.round(file.size / 1000) + " kB",
          base64: reader.result,
          file: file,
        };
        allFiles.push(fileInfo);
        if (allFiles.length === files.length) {
          onChange(allFiles[0]);
        }
      };
    }
  };

  return (
    <div className="restoration-details-form">
      <div className="restoration-details-form-header">Vehicle Details</div>
      <Form
        onSubmit={onSubmit}
        decorators={[focusOnError]}
        initialValues={carDetails}
        mutators={{
          // setDeleteImage: (args, state, utils) => {
          //   utils.changeValue(state, "deleteHeroImage", () => true);
          // },
          setLogoToNull: (args, state, utils) => {
            utils.changeValue(state, "logo", () => "");
          },
        }}
        render={({
          handleSubmit,
          form,
          submitting,
          pristine,
          values,
          invalid,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="restoration-details-form-body">
              <div className="form-col">
                {carDetailsArray.map((detail, index) => {
                  if (
                    detail === "year" ||
                    detail === "make" ||
                    detail === "model"
                  ) {
                    return (
                      <CompanyProfileFormField
                        key={index}
                        name={detail}
                        label={detail.replace(/([A-Z])/g, " $1").toUpperCase()}
                        validate={required}
                      />
                    );
                  } else {
                    return (
                      <CompanyProfileFormField
                        key={index}
                        name={detail}
                        label={detail.replace(/([A-Z])/g, " $1").toUpperCase()}
                      />
                    );
                  }
                })}
              </div>
              <div className="form-col">
                <div className="car-details-image-container">
                  {!newCar ? (
                    <HeroImageContainer
                      modalDispatch={modalDispatch}
                      carDetails={carDetails}
                      form={form}
                      deleteCarHeroImage={deleteCarHeroImage}
                      saveHeroImage={saveHeroImage}
                      fieldChange={fieldChange}
                    />
                  ) : (
                    <HeroImageContainerNewCar
                      values={values}
                      fieldURL={fieldURL}
                      carDetails={carDetails}
                      modalDispatch={modalDispatch}
                      fieldChange={fieldChange}
                      form={form}
                    />
                  )}
                </div>
                {carDetails && carDetails.owner ? null : newCar ? null : (
                  <>
                    <div className="restoration-details-form-small-header">
                      Owner
                    </div>
                    <CompanyProfileFormField
                      name={"ownerEmail"}
                      label="OWNER'S EMAIL"
                      ownerInput={true}
                      assignOwner={assignOwner}
                      style={{ marginBottom: 0 }}
                    />
                    {assignOwnerError ? (
                      <div className="assign-owner-error">Invalid Email</div>
                    ) : null}
                    {assignOwnerLoading ? (
                      <div className="spinner">
                        <div className="dot1"></div>
                        <div className="dot2"></div>
                      </div>
                    ) : null}
                    <div
                      className="assign-car-button"
                      onClick={() => assignOwner(values.ownerEmail)}
                    >
                      ASSIGN CAR PROFILE TO OWNER
                    </div>
                    {assignOwnerSuccess ? (
                      <div className="assign-owner-success">Success!</div>
                    ) : null}
                  </>
                )}
              </div>
            </div>

            {updateCarData && updateCarData.loading ? (
              <div className="spinner">
                <div className="dot1"></div>
                <div className="dot2"></div>
              </div>
            ) : null}
            {submitError ? (
              <div className="restoration-details-form-error">
                {submitError}
              </div>
            ) : null}
            <div className="company-profile-form-buttons">
              <div
                className="company-profile-form-buttons-button"
                onClick={() => onCancel()}
              >
                CANCEL
              </div>
              <button>SAVE</button>
            </div>
          </form>
        )}
      />
    </div>
  );
};

export default RestorationDetailsForm;

const HeroImageContainer = ({
  modalDispatch,
  carDetails,
  deleteCarHeroImage,
  saveHeroImage,
}) => {
  const [showingEdit, setShowEdit] = useState(false);
  const submitDelete = () => {
    deleteCarHeroImage();
    setShowEdit(false);
  };
  return (
    <>
      {showingEdit ? (
        <div className="car-details-image">
          <div
            className="delete-car-icon"
            onClick={(e) => {
              e.preventDefault();
              modalDispatch({
                type: "OPEN_MODAL",
                payload: {
                  component: "DeleteImageModal",
                  onSubmit: submitDelete,
                },
              });
            }}
          >
            <button className="edit-icons">
              <RedDeleteIcon />
            </button>
          </div>
          <img src={carDetails.heroImageUrl} alt="" />
        </div>
      ) : carDetails?.heroImageUrl ? (
        <div className="car-details-image">
          <img src={carDetails.heroImageUrl} alt="" />
          <Button
            onClick={(e) => {
              e.preventDefault();
              setShowEdit(true);
            }}
            text="EDIT"
            className="car-details-image-edit-label"
          />
        </div>
      ) : (
        <div className="car-details-image">
          <div className="add-car-photo-container">
            <label htmlFor="image-file" className="add-car-label">
              <div className="add-car-photo">
                <MissingCarMainTransparent />
              </div>
              <div className="add-with-icon">
                <GreenAddIcon />
              </div>
            </label>
          </div>
        </div>
      )}
      <Field name={"logo"}>
        {({ input, meta }) => {
          return (
            <>
              <input
                id="image-file"
                name="createLogo"
                type="file"
                onChange={(e) => {
                  saveHeroImage(e);
                }}
              />
              {meta.error && meta.touched ? (
                <div className="restoration-form-col-input-error">
                  {meta.error}
                </div>
              ) : null}
            </>
          );
        }}
      </Field>
    </>
  );
};

const HeroImageContainerNewCar = ({
  values,
  fieldURL,
  modalDispatch,
  fieldChange,
  form,
}) => {
  const [showingEdit, setShowEdit] = useState(false);
  const [inputKey, setInputKey] = useState(Date.now());
  return (
    <>
      {showingEdit ? (
        <div className="car-details-image">
          <div
            className="delete-car-icon"
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            <button
              className="edit-icons"
              onClick={() => {
                form.mutators.setLogoToNull();
                setInputKey(Date.now());
                setShowEdit(false);
              }}
            >
              <RedDeleteIcon />
            </button>
          </div>
          <img src={fieldURL} alt="" />
          <Button
            onClick={(e) => {
              e.preventDefault();
              setShowEdit(false);
            }}
            text="CANCEL"
            className="car-details-image-edit-label"
          />
        </div>
      ) : values.logo ? (
        <div className="car-details-image">
          <img src={fieldURL} alt="" />
          <Button
            onClick={(e) => {
              e.preventDefault();
              setShowEdit(true);
            }}
            text="EDIT"
            className="car-details-image-edit-label"
          />
        </div>
      ) : (
        <div className="car-details-image">
          <div className="add-car-photo-container">
            <label htmlFor="image-file" className="add-car-label">
              <div className="add-car-photo">
                <MissingCarMainTransparent />
              </div>
              <div className="add-with-icon">
                <GreenAddIcon />
              </div>
            </label>
          </div>
        </div>
      )}

      <Field name={"logo"}>
        {({ input, meta }) => {
          return (
            <>
              <input
                id="image-file"
                name="createLogo"
                type="file"
                key={inputKey}
                onChange={(e) => {
                  fieldChange(input.onChange, e);
                }}
              />
              {meta.error && meta.touched ? (
                <div className="restoration-form-col-input-error">
                  {meta.error}
                </div>
              ) : null}
            </>
          );
        }}
      </Field>
    </>
  );
};
