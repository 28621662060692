import React, { useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ApolloClient from "apollo-boost";
import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloProvider } from "@apollo/react-hooks";
import "./assets/scss/main.scss";

import PrivateRoute from "./components/routing/PrivateRoute";
import InternalRoutes from "./components/routing/InternalRoutes";
import NavBar from "./components/NavBar/NavBar";
import Footer from "./components/Footer/Footer";
import TermsConditions from "./components/Legal/TermsConditions";
import PrivacyPolicy from "./components/Legal/PrivacyPolicy";
import EndUserAgreement from "./components/Legal/EndUserAgreement";

import { MainContextProvider } from "./services/Context/Context";
import ApiConstants from "./constants/ApiConstants";
import LoginPageShop from "./components/LoginPageShop/LoginPageShopContainer";
import LoginPage from "./components/LoginPage/LoginPageContainer";
import SignUpPageShop from "./components/SignUpPageShop/SignUpPageShopContainer";
import PasswordResetPage from "./components/PasswordResetPage/PasswordResetPageContainer";
import ShopPasswordResetPage from "./components/ShopPasswordResetPage/ShopPasswordResetPageContainer";

function App() {
  const [modalState, setModalState] = useState("");

  const [authToken, setAuthToken] = useState(
    localStorage.ionToken ? true : false
  );
  const cache = new InMemoryCache({ addTypename: false });
  const client = new ApolloClient({
    uri: `${ApiConstants.API_URL}graphql`,
    cache,
    request: (operation) => {
      const token = localStorage.getItem("ionToken");
      operation.setContext({
        headers: {
          authorization: token ? token : "",
        },
      });
    },
  });

  const loginModal = (button) => {
    if (button === "login") {
      setModalState("login");
    } else {
      setModalState("signup");
    }
  };

  const PrivateRoutes = PrivateRoute(InternalRoutes);
  return (
    <ApolloProvider client={client}>
      <Router>
        <MainContextProvider>
          <Route
            children={(routeProps) => (
              <NavBar
                {...routeProps}
                loginModal={loginModal}
                authToken={authToken}
                setAuthToken={setAuthToken}
              />
            )}
          />
          <Switch>
            <Route
              exact
              path={"/sign-in"}
              render={(props) => (
                <LoginPage
                  {...props}
                  modalState={"login"}
                  setModalState={setModalState}
                  setAuthToken={setAuthToken}
                />
              )}
            />
            <Route
              exact
              path={"/welcome"}
              render={(props) => (
                <LoginPage
                  {...props}
                  modalState={"login"}
                  setModalState={setModalState}
                  setAuthToken={setAuthToken}
                />
              )}
            />
            <Route
              exact
              path={"/forgot-password"}
              render={(props) => (
                <PasswordResetPage
                  {...props}
                  modalState={"signup"}
                  setModalState={setModalState}
                  setAuthToken={setAuthToken}
                />
              )}
            />
            <Route
              exact
              path={"/shop-sign-in"}
              render={(props) => (
                <LoginPageShop
                  {...props}
                  modalState={"login"}
                  setModalState={setModalState}
                  setAuthToken={setAuthToken}
                />
              )}
            />
            <Route
              exact
              path={"/shop-owner-welcome"}
              render={(props) => (
                <LoginPageShop
                  {...props}
                  modalState={"login"}
                  setModalState={setModalState}
                  setAuthToken={setAuthToken}
                />
              )}
            />
            <Route
              exact
              path={"/shop-sign-up"}
              render={(props) => (
                <SignUpPageShop
                  {...props}
                  modalState={"signup"}
                  setModalState={setModalState}
                  setAuthToken={setAuthToken}
                />
              )}
            />
            <Route
              exact
              path={"/shop-forgot-password"}
              render={(props) => (
                <ShopPasswordResetPage
                  {...props}
                  modalState={"signup"}
                  setModalState={setModalState}
                  setAuthToken={setAuthToken}
                />
              )}
            />
            {/*<Route*/}
            {/*    exact*/}
            {/*    path={'/sign-up'}*/}
            {/*    render={(props) =>*/}
            {/*        <LoginPage*/}
            {/*            {...props}*/}
            {/*            modalState={'signup'}*/}
            {/*            setModalState={setModalState}*/}
            {/*            setAuthToken={setAuthToken}*/}
            {/*        /> }*/}
            {/*/>*/}
            <Route
              exact
              path={"/terms-and-conditions"}
              component={TermsConditions}
            />
            <Route exact path={"/privacy-policy"} component={PrivacyPolicy} />
            <Route
              exact
              path={"/end-user-agreement"}
              component={EndUserAgreement}
            />
            <Route
              path={"/"}
              render={(props) => (
                <PrivateRoutes {...props} authenticated={authToken} />
              )}
            />
          </Switch>
          <Footer />
        </MainContextProvider>
      </Router>
    </ApolloProvider>
  );
}

export default App;
