import React, { createContext, useReducer } from 'react';

let MainReducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_OWNER":
      return { ...state, ownerName: action.payload }
    default:
      return;
  }
};

let ModalReducer = (state, action) => {
  switch (action.type) {
    case "OPEN_MODAL":
      return {
        modalOpen: true,
        modalMetaData: action.payload,
      };
    case "CLOSE_MODAL":
      return {
        modalOpen: false,
        modalMetaData: {
          component: '',
        },
      }
    default:
      return state;
  }
};

const initialState = {
  ownerName: '',
};

const modalInitialState = {
  modalOpen: false,
  modalMetaData: {
    component: '',
  },
};

const MainContext = createContext([
  initialState,
  obj => obj
]);

const ModalContext = createContext([
  modalInitialState,
  obj => obj
]);

function MainContextProvider(props) {
  const [state, dispatch] = useReducer(MainReducer, initialState);

  return (
    <MainContext.Provider value={{state, dispatch}}>
      {props.children}
    </MainContext.Provider>
  )
}

function ModalContextProvider(props) {
  const [modalState, modalDispatch] = useReducer(ModalReducer, modalInitialState);

  return (
    <ModalContext.Provider value={{ modalState, modalDispatch }}>
      {props.children}
    </ModalContext.Provider>
  )
}

export { MainContext, MainContextProvider, ModalContextProvider, ModalContext };
