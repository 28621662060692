import React from 'react';

import LandingHeader from '../ShopPasswordResetPage/LandingHeader';

import './styles.scss';
import ShopSignUp from "./ShopSignUp/ShopSignUp";

const SignUpPageShopContainer = ({ history, modalState, setModalState, setAuthToken }) => {
  return(
    <div className="landing-owner">
      <LandingHeader history={history} setModalState={setModalState} />
      <ShopSignUp history={history} setModalState={setModalState} modalState={modalState} setAuthToken={setAuthToken} />
    </div>
  )
}

export default SignUpPageShopContainer;
