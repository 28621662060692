import React, { Component } from "react";

export default function (ComposedComponent, role) {
  class Authentication extends Component {
    componentDidMount() {
      if (!this.props.authenticated) {
        if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
          window.location.href = "http://ion-web-dev.s3-website-us-east-1.amazonaws.com";
        } else {
          window.location.href =
            "https://ionclassic.com";
          // window.location.href = 'http://localhost:8000'
        }
      }
    }

    componentDidUpdate(nextProps) {
      if (!nextProps.authenticated) {
        this.props.history.push("/sign-in");
      }
    }

    render() {
      return <ComposedComponent {...this.props} />;
    }
  }
  return Authentication;
}
