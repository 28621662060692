import React from 'react';

const NoMatch = (props) => {
  const onClick = () => {
    props.history.push('/dashboard/restorations');
  }

  return(
    <div className="no-match">
      <div className="no-match-header">404</div>
      <p>Sorry the page you're trying to access doesn't exist</p>
      <div className="button" onClick={onClick}>Back to the dashboard</div>
    </div>
  )
}

export default NoMatch;
