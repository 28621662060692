import React from 'react';

import Login from './Login/Login';
import LandingHeader from '../ShopPasswordResetPage/LandingHeader';

import './styles.scss';

const LoginPageContainer = ({ history, modalState, setModalState, setAuthToken }) => {
  return(
    <div className="landing-owner">
      <LandingHeader history={history} setModalState={setModalState} />
      <Login history={history} setModalState={setModalState} modalState={modalState} setAuthToken={setAuthToken} />
    </div>
  )
}

export default LoginPageContainer;
