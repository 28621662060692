import React, { Fragment, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';

import IconX from '../../../assets/images/Symbol-71 – 18.png';
import { Api } from '../../../services/Api';

const required = value => (value ? undefined : 'Required');

const ShopLoginForm = ({ history, modalState, setModalState, setAuthToken }) => {
  const [fetchCurrentUser, currentUserData] = useLazyQuery(GET_CURRENT_USER);
  const [loginError, setLoginError] = useState('');
  const [registerError, setRegisterError] = useState('');
  const [spinner, setSpinner] = useState(false);


  const onSubmit = (values) => {
    Api.post(`v1/managers/sessions`, values).then(
        response => {
          localStorage.setItem('ionToken', response.auth_token)
          setAuthToken(true);
          history.push('/dashboard/restorations');
          setModalState('');
        }
    ).catch(err => {
      if (err.status === 401) {
        setLoginError('Username or password is invalid')
        setSpinner(false);
      }
    })
  }
  if (currentUserData && currentUserData.data ) {

  }
  return(
      <div className="login-form">
        <div className="login-form-header">SIGN IN</div>
        <Form
            onSubmit={onSubmit}
            render={({ handleSubmit, form, submitting, pristine, values }) => (
                <form onSubmit={handleSubmit}>
                  <div className="login-form-input">
                    <Field name="email" validate={required}>
                      {({ input, meta }) => (
                          <Fragment>
                            <label className="login-form-input-label">EMAIL</label>
                            {meta.error && meta.touched && <div className="login-form-input-error">{meta.error}</div>}
                            <input {...input} type="text" placeholder="User Name" />
                          </Fragment>
                      )}
                    </Field>
                  </div>
                  <div className="login-form-input">
                    <Field name="password" validate={required}>
                      {({ input, meta }) => (
                          <Fragment>
                            <label className="login-form-input-label">PASSWORD (6 or more characters)</label>
                            {meta.error && meta.touched && <div className="login-form-input-error">{meta.error}</div>}
                            <input {...input} type="password" placeholder="Password" id="password" autoComplete={modalState === 'signup' ? 'new-password' : ''} />

                          </Fragment>
                      )}
                    </Field>
                  </div>
                  {loginError ? <div className="login-form-error">{loginError}</div> : null}
                  {currentUserData && currentUserData.loading ?
                      <div className="spinner">
                        <div className="dot1"></div>
                        <div className="dot2"></div>
                      </div>
                      :
                      <div className="login-form-button">
                        <button className="login-form-input-button">SIGN IN</button>
                      </div>
                  }
                  {spinner ?
                      <div className="spinner">
                        <div className="dot1"></div>
                        <div className="dot2"></div>
                      </div> : null
                  }
                  <div className="login-form-text">
                    <div>
                      <div className="link-section">Don't have an account? <a className="signup-link" href={'/shop-sign-up'}>Sign up</a></div>
                      <div className="link-section">Forgot your password? <a className="signup-link" href={'/shop-forgot-password'}>Recover</a></div>
                    </div>
                  </div>
                </form>
            )}
        />
      </div>
  )
}

export default ShopLoginForm;


const GET_CURRENT_USER = gql`
  {
    currentUser {
      subscriptionActive
    }
  }
`;
