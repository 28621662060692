import React, { useState, useContext } from "react";
import AddPhoto from "./AddPhoto";
import { useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { ModalContext } from "../../services/Context/Context";

const SliderContainer = ({
  fieldChange,
  category,
  carId,
  images,
  title,
  refetch,
  imagesLoading,
}) => {
  const { modalDispatch } = useContext(ModalContext);
  const [internalImages, setInternalImages] = useState(images || []);
  const [deleteImageFromShop] = useMutation(
    DELETE_IMAGE,
    {
      onCompleted: () => refetch(),
    }
  );
  const deleteImage = (imageId) => {
    modalDispatch({
      type: "DeleteImageModal"
    })
    const internalImagesStatuses = Object.assign([], images);
    internalImagesStatuses.find((i) => i.id === imageId).deleting = true;
    setInternalImages(internalImagesStatuses);
    deleteImageFromShop({
      variables: {
        input: {
          carId: carId,
          carImageId: imageId,
        },
      },
    });
  };

  const saveImages = (e, category) => {
    e.persist();
    fieldChange(e, category)
  }

  return (
    <div className="photos-body-section">
      <div className="photos-body-section-header">
          <span className="header-label">{title}</span>
      </div>
      <div className="photos-body-row">
        <AddPhoto
          shopId={carId}
          images={images}
          category={category}
          fieldChange={fieldChange}
          deleteImageFromShop={deleteImage}
          saveImages={saveImages}
          internalImagesDeleting={internalImages}
          imagesLoading
          modalDispatch={modalDispatch}
        />
      </div>
    </div>
  );
};

export default SliderContainer;

const DELETE_IMAGE = gql`
  mutation deleteImageFromShop($input: DeleteCarImageFromShopInput!) {
    deleteImageFromShop(input: $input) {
      success
    }
  }
`;
