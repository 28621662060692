import React from 'react';
import Carousel from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import {gql} from "apollo-boost";
import {useQuery} from "@apollo/react-hooks";
import ChevronLeft from "../../assets/images/ChevronLeft.png";

const SlideShow = (props) => {

	// let carId = props.match.params.id;

	const { loading, error, data } = useQuery(CAR, {
		variables: {
			id: props.match.params.id,
			carId: props.match.params.id,
			image_size: 'original'
		}
	});

	const chevronClick = () => {
		props.history.push('/dashboard/restorations');
	}

	if (loading) return <div>loading</div>;
	if (error) return <div>error</div>;
	if (data)
	return(
		<div className="slide-show">
			<div className="photos-header">
				<div className="photos-header-chevron" onClick={() => chevronClick()}><img src={ChevronLeft} alt="" /> </div>
				<div>SLIDE SHOW </div>
			</div>
			<div className='slider'>
				{data.car.carImages && data.car.carImages.length > 0 &&
				<Carousel arrows lazyLoad infinite draggable={false} slidesPerPage={1} animationSpeed={2000}
				          autoPlay={2500} >
					{data.car.carImages.map((image, index) => (
						<img src={image.imageUrl} alt="Car" key={index} onClick={() => this.openLightbox(index)} width={"95%"}/>
					))}
				</Carousel>
				}
			</div>

		</div>
	);
}


const CAR = gql`
query car( $id: ID!, $image_size: String!) {
  car( id: $id) {
    carImages {
      id
      imageUrl(size: $image_size)
        }
      
    }
  }
`;

export default SlideShow;
