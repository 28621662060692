import React, { useState, useContext } from 'react';
import { Form, Field } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import createDecorator from 'final-form-focus';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import MissingCarMainTransparent from "./../../assets/images/fallback-images/missing-car-main-transparent";
import GreenAddIcon from "../../assets/images/icons/green-add-icon";
import RedDeleteIcon from "./../../assets/images/icons/red-delete-icon";
import { ModalContext } from "../../services/Context/Context";

import CompanyProfileFormField from './CompanyProfileFormField';
import ServicesField from './ServicesField';

const focusOnError = createDecorator();

const required = value => (value ? undefined : 'Required')

const CompanyProfileForm = ({ onSubmit, initialValues, history, setActiveTab, loadingStatus, updateLogo }) => {
  const { loading, data } = useQuery(AVAILABLE_SERVICES);
  const [fieldURL, setFieldURL] = useState();
  const [editLogo, setEditLogo] = useState(false);
  const { modalDispatch } = useContext(ModalContext);
  const [deleteProfileImage] = useMutation(DELETE_PROFILE_IMAGE);
  const [initialFormValues, setInitialFormValues] = useState(initialValues);

  const onCancel = () => {
    setActiveTab('restorations');
    history.push('/dashboard/restorations');
  }

  const showUploadingModal = () => {
    modalDispatch({
      type: "OPEN_MODAL",
      payload: {
        component: "UploadingImageModal",
      },
    })
  }

  const closeUploadingModal = () => {
    modalDispatch({
      type: "CLOSE_MODAL",
      payload: {
        component: "UploadingImageModal",
      },
    })
  }

  const showUploadedModal = () => {
    modalDispatch({
      type: "OPEN_MODAL",
      payload: {
        component: "ImageUploadedModal",
      },
    })
  }

  const showDeleteModal = () => {
    modalDispatch({
      type: "OPEN_MODAL",
      payload: {
        component: "DeleteImageModal",
        onSubmit: handleImageDelete,
      },
    })
  }

  const closeDeleteModal = () => {
    modalDispatch({
      type: "CLOSE_MODAL",
      payload: {
        component: "DeleteImageModal",
      },
    })
  }

  const handleImageDelete = () => {
    if (initialFormValues.profileImageUrl) {
      deleteProfileImage({ variables: {
        input: {}
      }}).then( () => {
        modalDispatch({
          type: "CLOSE_MODAL",
          payload: {
            component: "DeleteImageModal",
          },
        });
        setInitialFormValues({...initialFormValues, profileImageUrl: null, logo: null});
        setFieldURL(null);
      }).catch(_err => {
        closeDeleteModal();
      })
    } else {
      setFieldURL(null);
      closeDeleteModal();
    }

  }

  const fieldChange = (onChange, e) => { // FUNCTION FOR GETTING BASE64 FROM UPLOADED IMAGE
    if (e.target.files.length === 0) {
      return
    }

    showUploadingModal();
    setFieldURL(URL.createObjectURL(e.target.files[0]));

    let files = e.target.files;
    // Process each file
    var allFiles = [];
    for (var i = 0; i < files.length; i++) {
      let file = files[i];
      // Make new FileReader
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let fileInfo = {
          name: file.name,
          type: file.type,
          size: Math.round(file.size / 1000) + ' kB',
          base64: reader.result,
          file: file,
        };
        allFiles.push(fileInfo);
        if (allFiles.length === files.length) {
          onChange(allFiles[0]);

          updateLogo && updateLogo({ logo_base64: reader.result, phoneNumber: initialFormValues.phoneNumber });

          closeUploadingModal();
          showUploadedModal();
        }
      }
    }
  }

  if (loading) return <p>Loading</p>
  return(
    <div>
      <Form
        onSubmit={onSubmit}
        decorators={[focusOnError]}
        mutators={{
          ...arrayMutators
        }}
        initialValues={initialFormValues ? initialFormValues : {
          shopServices: data.availableServices
        }}
        render={({handleSubmit, form, submitting, pristine, values, invalid }) => (
          <form onSubmit={handleSubmit}>
            <div className="company-profile-image">
              {values.profileImageUrl || fieldURL ?
                <div className="add-profile-logo-wrapper">
                  <div className="add-profile-logo">
                    <img src={values.profileImageUrl ? values.profileImageUrl : fieldURL} alt="" />
                  </div>

                  { editLogo ?
                      <label className="add-profile-logo-icon" onClick={showDeleteModal}>
                        <RedDeleteIcon styles={{width: 22, height: 22}} />
                      </label>
                    :
                      <label className="edit-profile-logo-label" onClick={() => setEditLogo(true)}>
                        EDIT
                      </label>
                  }
                </div>
              :
                <div className="add-profile-logo-wrapper">
                  <div className="add-profile-logo-missing">
                    <MissingCarMainTransparent styles={{marginTop: 13.1, width: 83.8, height: 83.8}}/>
                  </div>

                  <label className="add-profile-logo-icon" htmlFor="image-file">
                    <GreenAddIcon styles={{width: 22, height: 22}} />
                  </label>
                </div>
              }
              <Field name={'logo'}>
                {({ input, meta}) => {
                  return(
                    <>
                      <input id="image-file" name="createLogo" type="file" onChange={(e) => fieldChange(input.onChange, e)} key={Math.random().toString(36).substring(7)} />
                      {meta.error && meta.touched ? <div className='company-profile-form-col-input-error'>{meta.error}</div> : null}
                    </>
                  )
                }}
              </Field>
            </div>
            <div className="company-profile-header">Company Info</div>
            <div className="company-profile-form">
              <div className="company-profile-form-col">
                <CompanyProfileFormField name={'companyName'} label={'COMPANY NAME'} validate={required} />
                <CompanyProfileFormField name={'address'} label={'ADDRESS'} validate={required} />
                <CompanyProfileFormField name={'city'} label={'CITY'} validate={required} />
                <div className="company-profile-form-col-double-row">
                  <CompanyProfileFormField name={'state'} label={'STATE'} select={true} validate={required} />
                  <CompanyProfileFormField name={'zip'} label={'ZIP CODE'} validate={required} />
                </div>
                <CompanyProfileFormField name={'country'} label={'COUNTRY'} validate={required} />
                <CompanyProfileFormField name={'phoneNumber'} label={'PHONE NUMBER'} validate={required} />
              </div>
              <div className="company-profile-form-col">
                <CompanyProfileFormField name={'ownersName'} label={'OWNERS NAME'} validate={required} />
                <ServicesField
                  services={initialFormValues.shopServices}
                  values={form.values}
                />
              </div>


            </div>
            {loadingStatus ?
              <div className="spinner">
                <div className="dot1"></div>
                <div className="dot2"></div>
              </div> : null
            }
            <div className="company-profile-form-buttons">
              {history.location.pathname === '/create-company' ? null :
                <div className="company-profile-form-buttons-button" onClick={() => onCancel()} >CANCEL</div>
              }
              <button>SAVE</button>
            </div>
          </form>
        )}
      />
    </div>
  )
}

export default CompanyProfileForm;

const AVAILABLE_SERVICES = gql`
  {
    availableServices {
      id
      name
    }
  }
`;

const DELETE_PROFILE_IMAGE = gql`
mutation deleteProfileImageFromShop($input: DeleteProfileImageFromShopInput!) {
  deleteProfileImageFromShop(input: $input) {
    success
  }
}
`;
