import React from 'react';
import PropTypes from 'prop-types';
import Button from './../buttons/Button';
import LoadingSpinner from '../../../assets/images/icons/loading-spinner';

const UploadingImageModal = ({ onClose }) => {
  return (
    <div className="uploading-image-modal">
      <div className="uploading-image-header header">
        Uploading Image
      </div>
      <div className="loading-spinner">
        <LoadingSpinner />
      </div>
    </ div>
  )
}

UploadingImageModal.propTypes = {
  onClose: PropTypes.func,
}

export default UploadingImageModal;