import React from "react";
import { Form } from "react-final-form";
import arrayMutators from "final-form-arrays";
import createDecorator from "final-form-focus";
import CompanyProfileFormField from "../CompanyProfile/CompanyProfileFormField";

const focusOnError = createDecorator();

const required = (value) => (value ? undefined : "Required");

const InviteForm = ({ history, onSubmit }) => {
  const onCancel = () => {
    history.goBack();
  };

  return (
    <div className="invite-form">
      <Form
        onSubmit={onSubmit}
        mutators={{
          ...arrayMutators,
        }}
        decorators={[focusOnError]}
        // initialValues={{mechanics:[{}]}} USE FOR FIELD ARRAY
        render={({
          handleSubmit,
          reset,
          form: {
            mutators: { push, pop },
          },
          submitting,
          pristine,
          values,
          invalid,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="invite-mechanics-header">
              <div className="invite-mechanics-header-title">Mechanics</div>
              {/* UNCOMMENT TO HAVE MULTIPLE INVITES AT ONCE IF SUPPORTED BY BACKEND */}
              {/* <div className="invite-mechanics-header-icon" onClick={() => push('mechanics', undefined)}>
                <img src={PlusSymbol} alt="Add more mechanics" />
                <div>ADD ANOTHER</div>
              </div> */}
            </div>
            <CompanyProfileFormField
              name="firstName"
              label="MECHANIC'S FIRST NAME"
              validate={required}
            />
            <CompanyProfileFormField
              name="lastName"
              label="MECHANIC'S LAST NAME"
              validate={required}
            />
            <CompanyProfileFormField
              name="phoneNumber"
              label="MECHANIC'S PHONE NUMBER"
              validate={required}
            />
            {/* UNCOMMENT TO HAVE MULTIPLE INVITES AT ONCE IF SUPPORTED BY BACKEND */}
            {/* <FieldArray name="mechanics">
              {({ fields }) =>
              fields.map((name, index) => (
                <div className="invite-form-field-group" key={index}>
                  <div className="remove-mechanic" onClick={() => fields.remove(index)} ><img src={PlusSymbol} alt="Delete mechanic" /></div>
                  <CompanyProfileFormField
                  name={`${name}.firstName`}
                  label={"MECHANIC'S FIRST NAME"}
                  validate={required} />
                  <CompanyProfileFormField
                    name={`${name}.lastName`}
                    label={"MECHANIC'S LAST NAME"}
                    validate={required} />
                  <CompanyProfileFormField
                    name={`${name}.phone`}
                    label={"MECHANIC'S IPHONE NUMBER"}
                    validate={required} />
                </div>
              ))
              }
            </FieldArray> */}
            <div className="company-profile-form-buttons">
              <div
                className="company-profile-form-buttons-button"
                onClick={() => onCancel()}
              >
                CANCEL
              </div>
              <button>SAVE</button>
            </div>
          </form>
        )}
      />
    </div>
  );
};

export default InviteForm;
