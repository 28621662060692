import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Button extends Component {
  render() {
    const {
      props: { text, className, id, onClick, type, children, disabled, forID },
    } = this;

    return (
      <div className={`${className || ''} button-primary-container`}>
        {forID && <label htmlFor={forID} />}
        <button
          className='button-primary'
          onClick={onClick}
          type={type || ''}
          disabled={disabled}
          id={id}
        >
          {text || children}
        </button>
      </div>
    );
  }
}

export default Button;

Button.propTypes = {
  disabled: PropTypes.bool,
  employerSettings: PropTypes.object,
  text: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  id: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  style: PropTypes.object,
  type: PropTypes.string,
  children: PropTypes.node,
  forID: PropTypes.string,
};

Button.defaultProps = {
  className: 'button-primary',
  disabled: false,
  onClick: () => {},
  text: '',
  children: '',
};
