import React, { useContext, useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';

import CompanyProfileForm from './CompanyProfileForm';
import CompanyProfileMechanics from './CompanyProfileMechanics';
import CancelSubModal from './CancelSubModal';

import { MainContext } from '../../services/Context/Context';

const CompanyProfileContainer = ({ history, setActiveTab }) => {
  const { dispatch } = useContext(MainContext);
  const { loading, error, data, refetch } = useQuery(COMPANY_INFO, {fetchPolicy: 'network-only'});
  const [updateShop, updateShopData] = useMutation(UPDATE_COMPANY);
  const [updateLogo, updateLogoData] = useMutation(UPDATE_LOGO);
  const [cancelSub] = useMutation(CANCEL_SUBSCRIPTION);
  const [cancelSubModal, setCancelSubModal] = useState(false);

  const onSubmit = (values) => {
    let filteredServices = [];
    values.shopServices.forEach( service => {
      filteredServices.push({
        offered: service.offered,
        serviceId: service.serviceId,
      });
    })
    if (values.hasOwnProperty('logo') && values.logo) {
      updateShop({ variables: {
        input: {
          id: data.currentManager.shop.id,
          address: values.address,
          city: values.city,
          state: values.state.value,
          zip: values.zip,
          companyName: values.companyName,
          country: values.country,
          ownersName: values.ownersName,
          phoneNumber: values.phoneNumber,
          profileImageBase64: values.logo.base64,
          services: filteredServices,
        }
      }}).then(() => {
        refetch();
      }).then(() => {
        dispatch({ type: "UPDATE_OWNER", payload: values.ownerName})
        history.push('restorations');
      })
    } else {
      updateShop({ variables: {
        input: {
          id: data.currentManager.shop.id,
          address: values.address,
          city: values.city,
          state: values.state.value,
          zip: values.zip,
          companyName: values.companyName,
          country: values.country,
          ownersName: values.ownersName,
          phoneNumber: values.phoneNumber,
          services: filteredServices,
        }
      }}).then(() => {
        dispatch({ type: "UPDATE_OWNER", payload: values.ownerName})
        history.push('restorations');
      })
    }
  }

  const handleUpdateLogo = (values) => {
    updateLogo({ variables: {
      input: {
        id: data.currentManager.shop.id, // It's required field
        profileImageBase64: values.logo_base64,
        phoneNumber: values.phoneNumber, // It's required field
      }
    }})
  }

  if (loading) return <p>loading</p>
  else if (!data) return <p>loading</p>
  let shop = data.currentManager.shop
  return(
    <div className='company-profile'>
      {cancelSubModal ?
        <CancelSubModal
          setCancelSubModal={setCancelSubModal}
          cancelSub={cancelSub}
          history={history}
        />
        : null }
      <CompanyProfileForm
        onSubmit={onSubmit}
        history={history}
        setActiveTab={setActiveTab}
        initialValues={{
            ...shop,
            state: {value: shop?.state || '', label: shop?.state || ''},
            shopServices: shop?.shopServices || [],
          }}
        updateCompany={updateShop}
        loadingStatus={updateShopData?.loading}
        updateLogo={handleUpdateLogo}
      />
      {error ? <p>Error :</p> : null}
      <CompanyProfileMechanics
        mechanics={data?.currentManager?.shop?.mechanics || []}
        history={history}
      />
      <div className="company-profile-payment-button" onClick={() => history.push('/update-shop-subscription')}>
        <div >UPDATE PAYMENT INFO</div>
      </div>
      {data.currentManager.subscriptionActive ?
        <div className="company-profile-payment-button" onClick={() => setCancelSubModal(true)}>
          <div>CANCEL SUBSCRIPTION</div>
        </div>
        : null
      }
    </div>
  )
}

export default CompanyProfileContainer;

const COMPANY_INFO = gql`
  {
    currentManager {
      subscriptionActive
      shop {
        id
        address
        city
        state
        zip
        companyName
        country
        ownersName
        phoneNumber
        profileImageUrl
        shopServices {
          name
          offered
          serviceId
        }
        mechanics {
          firstName
          lastName
          email
        }
      }
    }
  }
`;

const UPDATE_COMPANY = gql`
mutation updateShop( $input: UpdateShopInput!) {
  updateShop(input: $input) {
    shop {
      id
    }
  }
}
`;

const CANCEL_SUBSCRIPTION = gql`
mutation cancelShopStripeSubscription {
  cancelShopStripeSubscription {
    success
  }
}
`;
// profileImageBase64

const UPDATE_LOGO = gql`
mutation updateShop($input: UpdateShopInput!) {
  updateShop(input: $input) {
    shop {
      id
    }
  }
}
`;
