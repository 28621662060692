import React, { Fragment, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';

import IconX from '../../../assets/images/Symbol-71 – 18.png';
import { Api } from '../../../services/Api';

const required = value => (value ? undefined : 'Required');

const ShopPasswordResetForm = ({ history, modalState, setModalState, setAuthToken }) => {
  const [fetchCurrentUser, currentUserData] = useLazyQuery(GET_CURRENT_USER);
  const [passwordReset, setPasswordReset] = useState(false);

  const recoverPassword = (email) => {
    Api.post(`v1/managers/forgot_password`, email).then(
      () => {
        setPasswordReset(false);
        setModalState('');
      }
    );
  };
  if (currentUserData && currentUserData.data ) {

  }
  return(
    <div className="login-form">
        <div className="login-form-recover">
          <div className="login-form-header">Recover Password</div>
          <Form
            onSubmit={recoverPassword}
            render={({ handleSubmit, form, submitting, pristine, values }) => (
              <form onSubmit={handleSubmit}>
                <div className="login-form-input">
                  <Field name="email" validate={required}>
                    {({ input, meta }) => (
                      <Fragment>
                        <label className="login-form-input-label">EMAIL</label>
                        {meta.error && meta.touched && <div className="login-form-input-error">{meta.error}</div>}
                        <input {...input} type="text" placeholder="User Name" disabled={passwordReset} />
                      </Fragment>
                    )}
                  </Field>
                </div>
                <div className="login-form-button">
                  <button className="login-form-input-button" disabled={passwordReset}>Recover Password</button>
                </div>
                <div className="login-form-text login-form-text-recover">An email will be sent to you with instructions to reset your password</div>
                <div className="login-form-text login-form-text-recover">Back to Sign in <a className="signup-link" href={'/shop-sign-in'}>Sign in</a></div>
              </form>
            )}
          />
        </div>


    </div>
  )
}

export default ShopPasswordResetForm;


const GET_CURRENT_USER = gql`
  {
    currentUser {
      subscriptionActive
    }
  }
`;
