import React from 'react';

import LoginForm from './LoginForm';

const Login = ({history, setAuthToken}) => {
  return(
    <div className="login-page">
      <LoginForm history={history} setAuthToken={setAuthToken} />
    </div>
  )
}

export default Login;
