import React from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';

import CompanyProfileForm from '../CompanyProfile/CompanyProfileForm';

const CreateCompanyContainer = (props) => {
  const [createShop] = useMutation(CREATE_COMPANY);
  const {data} = useQuery(COMPANY_INFO)

  const onSubmit = (values) => {
    let location = {
      pathname: '/dashboard/invite',
      state: { fromCreateCompany: true }
    }
    let filteredServices = [];
    values.shopServices.forEach( service => {
      filteredServices.push({
        offered: service.offered ? service.offered : false,
        serviceId: service.id,
      });
    })
    createShop({ variables: {
      input: {
        address: values.address,
        city: values.city,
        state: values.state.value,
        zip: values.zip,
        companyName: values.companyName,
        country: values.country,
        ownersName: values.ownersName,
        phoneNumber: values.phoneNumber,
        profileImageBase64: values.logo?.base64,
        services: filteredServices,
      }
    }}).then(response => {
      props.history.push(location)
    });
  }
  if (data) {
    if (data.currentManager.shop) {
      props.history.push('/dashboard/restorations');
    }
  }
  return(
    <div className="create-company">
      <div className="create-company-header">
        CREATE COMPANY PROFILE
      </div>
      <CompanyProfileForm
        history={props.history}
        initialValues={false}
        onSubmit={onSubmit}
      />
    </div>
  )
}

export default CreateCompanyContainer;

const CREATE_COMPANY = gql`
  mutation createShop( $input: CreateShopInput!) {
    createShop(input: $input) {
      clientMutationId
    }
  }
`;

const COMPANY_INFO = gql`
  {
    currentManager {
      subscriptionActive
      shop {
        id
        address
        city
        state
        zip
        companyName
        country
        ownersName
        phoneNumber
        profileImageUrl
        shopServices {
          name
          offered
          serviceId
        }
        mechanics {
          firstName
          lastName
          email
        }
      }
    }
  }
`;
