import React, { useState, useContext } from "react";
import { useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";

import InviteForm from "./InviteForm";
import InviteModal from "./InviteModal";
import WelcomeInviteModal from "./WelcomeInviteModal";
import { ModalContext } from "../../services/Context/Context.js";

const InviteContainer = (props) => {
  const { modalDispatch } = useContext(ModalContext);

  const [inviteModal, setInviteModal] = useState(false);
  const [createInvitation] = useMutation(INVITE_MECHANIC);
  const [welcomeModal, setWelcomeModal] = useState(
    props.location.state && props.location.state.fromCreateCompany
  );

  const onSubmit = (values) => {
    createInvitation({
      variables: {
        input: {
          ...values,
        },
      },
    });
    modalDispatch({
      type: 'OPEN_MODAL',
      payload: {
        component: 'InviteModal',
        onSubmit: () => props.history.push('company')        
      }
    });
  };

  return (
    <div className="invite">
      {welcomeModal ? (
        <WelcomeInviteModal setWelcomeModal={setWelcomeModal} />
      ) : null}
      {inviteModal ? (
        <InviteModal history={props.history} setInviteModal={setInviteModal} />
      ) : null}
      <div className="invite-header">
        Invite your <span className="invite-header-white">Mechanics</span> to
        start using the{" "}
        <span className="invite-header-white">iON Mobile App</span>.
      </div>
      <div className="invite-mechanics">
        <InviteForm history={props.history} onSubmit={onSubmit} />
      </div>
    </div>
  );
};

export default InviteContainer;

const INVITE_MECHANIC = gql`
  mutation createInvitation($input: CreateInvitationInput!) {
    createInvitation(input: $input) {
      invitation {
        id
      }
    }
  }
`;
