import React from 'react';

import ShopSignUpForm from './ShopSignUpForm';
import './styles.scss';

const ShopSignUp = ({history, modalState, setModalState, setAuthToken}) => {
  return(
    <div className="login-page">
      <ShopSignUpForm history={history} setModalState={setModalState} modalState={modalState} setAuthToken={setAuthToken} />
    </div>
  )
}

export default ShopSignUp;
