import React, { useRef } from "react";
import PropTypes from "prop-types";
import Button from "../buttons/Button";
import CheckIcon from "../../../assets/images/icons/check";

const ImageUploadedAddModal = ({
  onClose,
  modalData: { onSubmit, categoryId },
}) => {
  const inputRef = useRef();

  return (
    <div className="image-uploaded-add-modal">
      <div className="check-icon">
        <CheckIcon />
      </div>
      <div className="uploading-image-header header">Image Uploaded</div>
      <div className="button-row add">
        <Button
          text="Add Another Image"
          onClick={() => inputRef.current.click()}
        >
          Add Another Image
        </Button>
        <Button
          text="Return to Car Profile"
          onClick={(e) => {
            e.persist();
            onClose();
          }}
        />
        <input
          id={"upload"}
          className="add-photo-input"
          ref={inputRef}
          type="file"
          onChange={(e) => onSubmit(e, categoryId)}
          multiple={true}
        />
      </div>
    </div>
  );
};

ImageUploadedAddModal.propTypes = {
  onClose: PropTypes.func,
};

export default ImageUploadedAddModal;
