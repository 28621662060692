import React from "react";
import PropTypes from "prop-types";
import Button from "./../buttons/Button";

const DeleteImageModal = ({ onClose, modalData: { onSubmit } }) => {
  return (
    <div className="delete-image-modal">
      <div className="delete-warning-header header">WARNING!</div>
      <div className="warning-text">
        You are about to permanently delete a photo, do you want to continue?
      </div>
      <div className="yes-no-button-row">
        <Button
          text="Yes, Delete Photo"
          onClick={(e) => {
            e.preventDefault();
            onSubmit();
            onClose();
          }}
        />
        <Button
          text="No, Cancel"
          onClick={(e) => {
            e.preventDefault();
            onClose();
          }}
        />
      </div>
    </div>
  );
};

DeleteImageModal.propTypes = {
  onClose: PropTypes.func,
};

export default DeleteImageModal;
