import React from 'react';

const TermsConditions = () => {
  return(
    <div className="policy-info">
        <p className="policy-info-header">
        iONclassic Terms & Conditions of Use
        </p>
        <p>Date of Last Revision: Sept. 18, 2020</p>
        <p>
          These Terms of Service (“Terms”) govern your access to and use of the website, mobile application, and services, including without limitation,
          merchandise, products, and Subscriptions (as defined herein) (collectively “Services”) (together with the Services, “Platform”), provided by
          iONclassic, LLC. These Terms constitute a binding agreement between iONclassic, LLC, and you or any company that you represent. By accessing
          or using the Platform, you accept and agree to these Terms. If you do not accept and agree to these Terms, you are not permitted to use the Platform.
        </p>
        <p>
            iONclassic (as defined herein) reserves the right, in its sole and absolute discretion, to amend, supplement, or otherwise modify these Terms from time
            to time. Such modified Terms will take effect immediately for new users and upon the passing of 30 days for existing users.  As an existing user, your
            continued use of the Platform after modified Terms have taken effect shall constitute your acceptance of such modified Terms.
        </p>
        <ol>
            <li>
                <p>
                    <strong>
                        Definitions
                    </strong>
                    The following terms shall have the meanings ascribed to them here.
                </p>
               <ol type='a'>
                   <li>
                       <p>
                           "<strong>Account</strong>" means the account associated with your email address, as applicable.
                       </p>
                   </li>
                   <li>
                       <p>
                           "<strong>Intellectual Property Rights</strong>" means the account associated with your email address, as applicable.
                       </p>
                   </li>
                   <li>
                       <p>
                           “<strong>iONclassic</strong>,” “<strong>we</strong>,” “<strong>our</strong>,” “<strong>company</strong>,” or “<strong>us</strong>” means iONclassic, LLC, a Florida limited liability company.
                       </p>
                   </li>
                   <li>
                       <p>
                           “<strong>User(s)</strong>,” “<strong>you</strong>,” or “<strong>your</strong>” means an individual who opens an Account and uses the Platform. Users may register an Account on behalf of a legal entity.
                           If you are registering on behalf of a legal entity: (i) these Terms are a contract between us on the one hand and you as an individual together with
                           that entity, and use of the terms “<strong>User</strong>,” “<strong>you</strong>,” and “<strong>your</strong>” herein shall refer collectively to you as an individual together with that entity; and (ii)
                           you represent and warrant that you are an authorized representative of that entity entity with the authority to bind that entity to these Terms.

                       </p>
                   </li>
               </ol>
            </li>
            <li>
                <p>
                    <strong>
                        Overview
                    </strong>
                </p>
                <p>
                    iONclassic is an online venue providing Users a Platform for the digital documentation of restoration for classic automobiles, motorcycles, and boats.
                    Users with an active Account may post information, pictures, videos, and messages regarding their restoration activities. Users must register for an
                    Account with their email address or as otherwise provided herein in order to use the Platform. We facilitate the connection among Users through their
                    Accounts, and we provide the means for them to communicate with each other.
                </p>
            </li>
            <li>
                <p>
                    <strong>
                        Eligibility
                    </strong>
                </p>
                <p>
                    In order to use the Platform, you must create an Account by providing Company with an electronic mail address and other information (“Registration Data”).
                    We may, at our absolute discretion, refuse to register any person or entity as a User.
                </p>
                <p>
                    You represent and warrant that all Registration Data and other Personal Information, as defined by iONclassic’s Privacy Policy, you provide us from time to time is
                    truthful, accurate, current, and complete, and you agree not to misrepresent your identity or your Registration Data or Personal Information. You agree to promptly
                    notify us of changes to your Personal Information by updating your Account through the Platform. Company assumes no duty to verify such information. If you provide
                    any information that is untrue, inaccurate, not current, or incomplete, or Company has reasonable grounds to suspect that such information is untrue, inaccurate,
                    not current, or incomplete, Company has the right to suspend or terminate your Account and refuse any and all current or future use of the Platform (or any portion
                    thereof).
                </p>
                <p>
                    You must not use the Platform, and you are not eligible to and may not register for an Account if:
                </p>
                <ol type="a">
                    <li>
                        <p>
                            you are less than eighteen (18) years or age or otherwise legally incompetent to enter into a binding contract;
                        </p>
                    </li>
                    <li>
                        <p>
                            you are a person who is barred from using the Platform under the laws of the United States or any other applicable jurisdiction – meaning,
                            for example and without limitation, that you appear on the U.S. Treasury Department’s list of Specially Designated Nationals or face any other
                            similar prohibition;
                        </p>
                    </li>
                    <li>
                        <p>
                            you are suspended from using the Platform; or
                        </p>
                    </li>
                    <li>
                        <p>
                            you do not hold a valid email address.
                        </p>
                    </li>
                </ol>
            </li>
            <li>
                <p>
                    <strong>
                        Proprietary Rights; Limited License
                    </strong>
                </p>
                <p>
                    With the exception of User Content (as defined herein), as between you and us, we own, solely and exclusively, all rights, title, and interest in and to the
                    Platform, code, data, and other proprietary materials thereon, the design, organization, and the compilation of the content, code, data, and materials (collectively,
                    “Materials”) making up the Platform, including without limitation all Intellectual Property Rights therein. Your use of the Platform does not grant you ownership of
                    any Materials you may access on or through the Platform. You may access and view the Materials on the Platform, and may use or copy such content for your personal
                    use only. Unless otherwise noted herein or on the Platform, use of the Platform is strictly for your personal use.
                </p>
                <p>
                    iONclassic may provide Users with the opportunity to download certain of portions of its Materials, to include protected trademarks, from the Platform. If you download
                    or otherwise obtain such trademarks, iONClassic grants you a limited right to: (i) use such trademarks for the sole purpose of referencing the Platform; and (ii) to use
                    only such trademarks that are made available with the permission of iONclassic. You may not: (i) alter or change the appearance of such trademarks, (ii) use such
                    trademarks in any manner that suggests iONclassic sponsors or endorses a product, service, promotion, contest; or (iii) use such Marks for any other purpose deemed
                    by iONclassic to be inappropriate. iONclassic may revoke your right to such trademarks at any time at our sole discretion.
                </p>
            </li>
            <li>
                <p>
                    <strong>
                        User Content
                    </strong>
                </p>
                <p>
                    You retain all Intellectual Property rights in and to any content that you post on or otherwise enter into the Platform, including without limitation, photos,
                    videos, messages, various forms of communication as made available to you through the Platform from time to time (collectively, “User Content”). You grant
                    iONclassic a worldwide, perpetual, irrevocable, royalty-free, non-exclusive right and license to exercise any and all copyright, trademark, publicity, and database
                    rights you have in the content, in all media known now or in the future.
                </p>
                <p>
                    You acknowledge and agree that we: (i) act only as a forum for the online distribution and publication of User Content; (ii) make no warranty that User Content will
                    be made available on the Platform; (iii) we may limit the amount of User Content you may post and may charge a fee related to User Content at any time, in our sole
                    discretion; (iv) have the right (but not the obligation) to take any action deemed appropriate by us with respect to any User Content, including without limitation
                    the monitoring, posting, and removal of User Content; (v) have no responsibility or liability for the deletion or failure to store any User Content, whether or not
                    the User Content was actually made available on the Platform; (vi) exercise no editorial control over any User Content available on the Platform; and (vii) may reject,
                    approve, or modify your User Content at our sole discretion.
                </p>
                <p>
                    You represent and warrant that your User Content:
                </p>
                <ol type="a">
                    <li>
                        <p>
                            will not infringe upon or misappropriate any copyright, patent, trademark, trade secret, or other Intellectual Property Right or proprietary right or
                            right of publicity or privacy of any third party;
                        </p>
                    </li>
                    <li>
                        <p>
                            will not violate any applicable law or regulation;
                        </p>
                    </li>
                    <li>
                        <p>
                            will not otherwise violate the provisions of Sections 4 and 6.
                        </p>
                    </li>
                </ol>
                <p>You agree that: (i) you are solely responsible for your User Content; and (ii) you will refrain from posting, transmitting, or otherwise publishing any of the following:
                </p>
                <ol type="a">
                    <li>
                        <p>
                            User Content that is unlawful, defamatory, hateful, harassing, threatening, invasive of privacy or publicity rights, abusive, inflammatory, fraudulent or
                            otherwise objectionable or harmful;
                        </p>
                    </li>
                    <li>
                        <p>
                            User Content that is obscene, pornographic, indecent, lewd, sexually suggestive, including without limitation photos, videos or other User Content
                            containing nudity;
                        </p>
                    </li>
                    <li>
                        <p>
                            User Content that would constitute, encourage or provide instructions for a criminal offense, violate the rights of any party, endanger national security,
                            or that would otherwise create liability or violate any local, state, national or international law;
                        </p>
                    </li>
                    <li>
                        <p>
                            User Content that may infringe or violate any patent, trademark, trade secret, copyright or other intellectual or other proprietary right of any party;
                        </p>
                    </li>
                    <li>
                        <p>
                            User Content that impersonates any person or entity or otherwise misrepresents your affiliation with a person or entity;
                        </p>
                    </li>
                    <li>
                        <p>
                            unsolicited messages containing promotions, political campaigning, advertising or solicitations; and
                        </p>
                    </li>
                    <li>
                        <p>
                            private information of any third party, including, without limitation: addresses, phone numbers, email addresses, social security numbers and credit card numbers.
                        </p>
                    </li>
                </ol>
                <p>
                    Using the Platform’s functionality for deleting, hiding, or otherwise disposing of information does not imply permanent deletion of User Content or information.
                </p>
            </li>
            <li>
                <p>
                    <strong>
                        Using iONclassic
                    </strong>
                </p>
            <p>
                Unless otherwise specifically indicated in these Terms or on the Platform, while using the Platform, you must not:
            </p>
            <ol>
                <li>
                    <p>
                        infringe any state or federal laws or regulations, third party rights, or any policies that are posted on the Platform;
                    </p>
                </li>
                <li>
                    <p>
                        fail to deliver payment for Services delivered to you;
                    </p>
                </li>
                <li>
                    <p>
                        take any action that may undermine the iONclassic feedback or reputation systems (such as displaying, importing, or exporting feedback information or using
                        it for purposes unrelated to the Platform);
                    </p>
                </li>
                <li>
                    <p>
                        transfer, sell, or rent your iONclassic account (including User Content) and Username to another party without our consent;
                    </p>
                </li>
                <li>
                    <p>
                        access or use another User’s account without their express consent;
                    </p>
                </li>
                <li>
                    <p>
                        distribute or post spam, unsolicited, or bulk electronic communications, chain letters, or pyramid schemes;
                    </p>
                </li>
                <li>
                    <p>
                        distribute viruses, harmful code, phishing scams, or any other content or technologies, or otherwise take any other actions, that do or are intended to harm
                        iONclassic, the Platform, or any Users or third parties, or their interests or property (including their Intellectual Property Rights, privacy rights, and
                        publicity rights);
                    </p>
                </li>
                <li>
                    <p>
                        “frame,” “mirror,” or otherwise incorporate any part of the Platform into any other website, app, or system;
                    </p>
                </li>
                <li>
                    <p>
                        modify, translate, adapt, edit, decompile, disassemble, or reverse engineer any software programs used by us in connection with the Platform;
                    </p>
                </li>
            </ol>
        </li>
            <li>
                <p>
                    <strong>
                        Links to Third-Party Materials
                    </strong>
                </p>
                <p>
                    The Platform may display, include, or make available links to other websites and related third-party products and services, including through third-party advertising
                    (collectively, “Third-Party Materials”). iONclassic is not responsible for the content, accuracy, or opinions provided by Third-Party Materials, and Third-Party
                    Materials are not investigated, monitored, or checked for accuracy or completeness by us. No Third-Party Materials are approved or endorsed by iONclassic.
                    If you access Third-Party Materials, you do so at your own risk and should review such third-parties’ policies for your better understanding. iONclassic is not
                    responsible or liable for any loss or damage of any kind incurred as the result of your direct dealings with third-party providers or otherwise resulting from the
                    presence of Third-Party Materials on the Platform.
                </p>
            </li>
            <li>
                <p>
                    <strong>
                        Subscriptions
                    </strong>
                </p>
                <p>
                    The Platform may display, include, or make available links to other websites and related third-party products and services, including through third-party advertising
                    (collectively, “Third-Party Materials”). iONclassic is not responsible for the content, accuracy, or opinions provided by Third-Party Materials, and Third-Party
                    Materials are not investigated, monitored, or checked for accuracy or completeness by us. No Third-Party Materials are approved or endorsed by iONclassic. If you
                    access Third-Party Materials, you do so at your own risk and should review such third-parties’ policies for your better understanding. iONclassic is not responsible
                    or liable for any loss or damage of any kind incurred as the result of your direct dealings with third-party providers or otherwise resulting from the presence of
                    Third-Party Materials on the Platform.
                </p>
            </li>
            <li>
                <p>
                    <strong>
                        Fees
                    </strong>
                </p>
                <p>
                    Depending on your User designation, you may be charged fees to purchase Services offered on the Platform. If you are charged fees, you will have an opportunity to
                    review and accept the fees that you will be charged, which we may change from time to time and will update by placing on our Platform. We may choose to temporarily
                    change the fees for our Services for promotional events (for example, temporary discounts) or new Services, and such changes are effective when we post a temporary
                    promotional event or new Service on the Platform, or as notified through promotional correspondence.
                </p>
                <p>
                    You must provide certain information in order to facilitate purchases, including a credit card number and other related payment information ("<strong>Payment Information</strong>“) as
                    required by iONclassic and our third party payment processor. Our handling of your Payment Information shall be governed by iONclassic’s Privacy Policy.
                </p>
                <p>
                    You agree to pay iONclassic all charges for any purchases made through the Platform inclusive of all taxes or other fees that may be imposed on your payments, as
                    displayed to you at the time of payment. You authorize iONclassic to charge your chosen payment method for such purchases. You authorize iONclassic to correct any
                    billing errors or mistakes even if iONclassic has already requested or received payment. In the event your payment method information changes or is updated, you
                    authorize iONclassic to obtain such changed or updated information from any relevant service provider as may be reasonably necessary to continue your use of the Platform
                    uninterrupted.
                </p>
                <p>
                    Unless otherwise provided herein, charges for purchases are non-refundable, but you may ask for a refund at any time by sending written notice to help@ionclassic.com,
                    or by mailing or delivering written notice to iONclassic, LLC, [ADDRESS]. We may make refunds upon your request in our sole discretion.
                </p>
                <p>
                    A chargeback or reversed payment initiated by you may result in iONclassic’s election to terminate your account immediately in its sole discretion.
                </p>
            </li>
            <li>
                <p>
                    <strong>
                        Taxes
                    </strong>
                </p>
                <p>
                    You are responsible for paying any taxes that accrue to you by your use of the Platform, including any goods and services or value-added taxes. You acknowledge that
                    you must comply with your obligations under tax provisions in your jurisdiction, including, without limitation, any and all applicable state and federal taxes.
                </p>
            </li>
            <li>
                <p>
                    <strong>
                        Notification of Copyright Infringement
                    </strong>
                </p>
                <p>
                    iONclassic respects the intellectual property rights of others and encourages its users to do the same. In accordance with the Digital Millennium Copyright Act
                    ("DMCA"), iONclassic will respond expeditiously to notices of alleged infringement that are reported to iONclassic's Designated Copyright Agent. If you are a
                    copyright owner, authorized to act on behalf of one, or authorized to act under any exclusive right under copyright, please report alleged copyright infringements
                    taking place on or through the Platform by completing the following notice ("Notice") and delivering it via email to help@iONclassic.com or by U.S. mail to the address
                    provided below.
                </p>
                <p>
                    DMCA NOTICE OF ALLEGED INFRINGEMENT
                </p>
                <ol>
                    <li>
                        <p>
                            Identify the copyrighted work that you claim has been infringed. If you believe multiple copyrighted works to have been infringed, you may provide a
                            representative list of the copyrighted works.
                        </p>
                    </li>
                    <li>
                        <p>
                            Identify the Platform material you claim is infringing, including at a minimum, if applicable, the URL of the link shown on the Platform or the location
                            within the Platform where such material is presented.
                        </p>
                    </li>
                    <li>
                        <p>
                            Provide your mailing address, telephone number, and, if available, email address.
                        </p>
                    </li>
                    <li>
                        <p>
                            Include both of the following statements in the body of the Notice:
                        </p>
                        <p>
                            (i) "I hereby state that I have a good faith belief that the disputed use of the copyrighted material is not authorized by the copyright owner,
                            its agent, or the law (e.g., as a fair use)"; and
                        </p>
                        <p>
                            (ii) "I hereby state that the information in this Notice is accurate and, under penalty of perjury, that I am the owner, or authorized to act on behalf of
                            the owner, of the copyright or of an exclusive right under the copyright that is allegedly infringed."
                        </p>
                    </li>
                    <li>
                        <p>
                            Provide your full legal name and your electronic or physical signature.
                        </p>

                    </li>
                </ol>
                <p>
                    In addition to an email delivery, please deliver this Notice, with all items completed, to: iONclassic, LLC, ATTN: iONclassic Copyright Agent, [ADDRESS].
                </p>
                <p><strong>Counter Notices</strong></p>
                <p>
                    A poster of allegedly infringing material may make a counter notice pursuant to Sections 512(g)(2) and 512(g)(3) of the DMCA. When iONclassic receives a counter
                    notice, it may reinstate the material in question. To provide a counter notice to us, please return the following form to the iONclassic Copyright Agent.
                </p>
                <p>COUNTER NOTICE</p>
                <ol>
                    <li>
                        <p>
                            Identification of the material that has been removed or to which access has been disabled on the Platform and the location at which the material appeared
                            before it was removed or access to it was disabled:
                        </p>
                    </li>
                    <li>
                        <p>
                            A statement as follows: "I hereby state under penalty of perjury that I have a good faith belief that the material was removed or disabled as a result of mistake or misidentification of the material to be removed or disabled."
                        </p>
                    </li>
                    <li>
                        <p>
                            Your name, address, telephone number and, if available, email address:
                        </p>
                    </li>
                    <li>
                        <p>
                            A statement as follows: “I hereby consent to the jurisdiction of the Federal District Court for the judicial district in which my address is located or,
                            if my address is outside of the United States, for any judicial district in which iONclassic may be found, and I will accept service of process from the
                            complaining party who notified iONclassic of the alleged infringement or an agent of such person.”
                        </p>
                    </li>
                    <li>
                        <p>
                            Your physical or electronic signature.
                        </p>
                    </li>
                </ol>
                <p>
                    The Counter Notice should be delivered to iONclassic, LLC, ATTN: iONclassic Copyright Agent, [ADDRESS].
                </p>
                <p>
                    No act or omission of iONclassic in furtherance of the provisions of this Section 11 shall give rise to any liability of iONclassic with respect to any content
                    posted by any User or other third party.
                </p>
            </li>
            <li>
                <p>
                    <strong>
                        Inactive Accounts
                    </strong>
                </p>
                <p>
                    We reserve the right to close an inactive User Accounts that have not been logged into for a period of time greater than six (6) months.
                </p>
            </li>
            <li>
                <p>
                    <strong>
                        Right to Refuse Service
                    </strong>
                </p>
                <p>
                    We may close, suspend, or limit your access to your Account without reason or without limitation:
                </p>
                <ol type="a">
                    <li>
                        <p>
                            if we determine that you have breached, or are acting in breach of, these Terms;
                        </p>
                    </li>
                    <li>
                        <p>
                            if we determine that you have infringed any third party’s legal rights (resulting in actual or potential claims), including infringing Intellectual Property Rights;
                        </p>
                    </li>
                    <li>
                        <p>
                            if we determine that you have engaged, or are engaging, in fraudulent or illegal activities;
                        </p>
                    </li>
                    <li>
                        <p>
                            you do not respond to account verification requests;
                        </p>
                    </li>
                    <li>
                        <p>
                            you do not complete account verification when requested;
                        </p>
                    </li>
                    <li>
                        <p>
                            to manage any risk of loss to us, a User, or any other person; or
                        </p>
                    </li>
                    <li>
                        <p>
                            for other reasons we may elect.
                        </p>
                    </li>
                </ol>
            </li>
            <li>
                <p>
                    <strong>
                        Remedies
                    </strong>
                </p>
                <p>
                    Without limiting our other remedies, to the extent you have breached these Terms, you must pay us all fees incurred prior to termination of your Account and reimburse
                    us for all losses, costs, and reasonable expenses (including legal fees) related to investigating such breach and collecting such fees. The damages that iONclassic
                    will sustain as a result of your breach of these Terms will be substantial and will potentially include without limitation fines and other related expenses imposed
                    on us by our payment processors which damages may be extremely difficult and impracticable to ascertain. If you breach these Terms, we may pursue all available
                    legal remedies to recover our losses.
                </p>
            </li>
            <li>
                <p>
                    <strong>
                        Closing Your Account
                    </strong>
                </p>
                <p>
                    You may close your Account at any time. Account closure is subject to your paying any outstanding fees or amounts owing on the Account. iONclassic will
                    retain your personal information only as long as necessary to perform the Services owed to you under these Terms or collect payment for such Services,
                    but closing your account does not necessarily imply deletion or removal of all of the information we hold.
                </p>
            </li>
            <li>
                <p>
                    <strong>
                        Indemnity
                    </strong>
                </p>
                <p>
                    You agree to indemnify, defend, and hold harmless Company and its officers, directors, managers, members, employees, agents, affiliates, successors, and assigns
                    from and against any and all losses, damages, liabilities, deficiencies, claims, actions, judgments, settlements, interest, awards, penalties, fines, costs,
                    or expenses of whatever kind, including without limitation reasonable attorneys’ fees, arising from or relating to your use or misuse of the Platform,
                    your breach of these Terms, or the User Content you submit or make available through the Platform.
                </p>
            </li>
            <li>
                <p>
                    <strong>
                        Security
                    </strong>
                </p>
                <p>
                    You must immediately notify us upon becoming aware of any unauthorized access or any other security breach to the Platform or your Account
                    and do everything possible to mitigate the unauthorized access or security breach (including preserving evidence and notifying appropriate authorities).
                    Your User Account is yours only, and you must not share your password with others. You are solely responsible for securing your password and for all
                    activities that occur under your Account. We will not be liable for any loss or damage arising from unauthorized access of your Account resulting from your
                    failure to secure your password.
                </p>
                <p>
                    We use commercially reasonable security measures to protect your Account and User Content though we cannot guarantee absolute security of your Account.
                    iONclassic is not responsible or liable for any third party access to or use of your Account or the User Content you post. In the event of an unauthorized
                    access or security breach of any type, notify us as soon as possible by telephone at [PHONE] between the hours of [TIME FRAME] on [DAYS] or in writing at
                    help@ionclassic.com.
                </p>
            </li>
            <li>
                <p>
                    <strong>
                        Disclaimer of Warranties
                    </strong>
                </p>
                <p>
                    OUR SERVICES, THE PLATFORM, AND ALL CONTENT ON IT, ARE provided to you “AS IS” and with all faults and defects without warranty of any kind.
                    To the maximum extent permitted under applicable law, Company on its own behalf and on behalf of its affiliates and its and their respective
                    licensors and service providers, expressly disclaims all warranties, whether express, implied, statutory, or otherwise, with respect to the Platform,
                    including all implied warranties of merchantability, fitness for a particular purpose, title, and non-infringement, and warranties that may arise out
                    of course of dealing, course of performance, usage, or trade practice. Without limitation to the foregoing, Company provides no warranty or undertaking,
                    and makes no representation of any kind that the Platform will meet your requirements, achieve any intended results, be compatible, or work with any other
                    software, applications, systems, or services, operate without interruption, meet any performance or reliability standards, or be error-free,
                    or that any errors or defects can or will be corrected.
                </p>
                <p>
                    Some jurisdictions do not allow the exclusion of or limitations on implied warranties or the limitations on the applicable statutory rights of a consumer,
                    so some or all of the above exclusions and limitations may not apply to you.
                </p>
            </li>
            <li>
                <p>
                    <strong>
                        Limitation of Liability
                    </strong>
                </p>
                <p>
                    In no event shall we, our related entities, our affiliates, or staff be liable under any cause of action, including without limitation tort, breach of contract,
                    or warranty for:
                </p>
                <ol type="a" >
                    <li>
                        <p>
                            any indirect, special, incidental, or consequential damages that may be incurred by you;
                        </p>
                    </li>
                    <li>
                        <p>
                            any loss of income, business, or profits (whether direct or indirect) that may be incurred by you;
                        </p>
                    </li>
                    <li>
                        <p>
                            any claim, damage, or loss that may be incurred by you as a result of any of your transactions or interactions involving the Platform;
                        </p>
                    </li>
                    <li>
                        <p>
                            any loss, claim, or damages of whatsoever kind of nature, whether direct, indirect, general, or special, arising out of or relating to the conduct
                            of any User or anyone else in connection with the use of the Platform, including, without limitation, death, bodily injury, emotional distress,
                            or any other damages resulting from communications or meetings with other Users or persons User meets through the Services or Platform; or
                        </p>
                    </li>
                    <li>
                        <p>
                            any services provided by any third party.
                        </p>
                    </li>
                </ol>
                <p>
                    The limitations on our liability to you above shall apply whether or not we, our related entities, our affiliates, or staff have been advised of the possibility
                    of such losses or damages arising.
                </p>
                <p>
                    Notwithstanding the above provisions, nothing in these Terms is intended to limit or exclude any liability on the part of us and our affiliates and related entities
                    where and to the extent that applicable law prohibits such exclusion or limitation. In no event shall iONclassic’s total aggregate liability, under or in relation
                    to any warranty or condition implied by law, be more than the aggregate sum total of fees paid by you to iONclassic, if any, for your access to the Platform or your
                    purchase of products on the Platform in the last twelve (12) months. As some jurisdictions do not allow some of the exclusions or limitations as established above,
                    some of these exclusions or limitations may not apply to you. In that event, the liability will be limited as far as legally possible under the applicable law.
                    We may plead these Terms in bar to any claim, action, proceeding, or suit brought by you against us for any matter arising out of any transaction or otherwise in
                    respect of these Terms.
                </p>
            </li>
            <li>
                <p>
                    <strong>
                        Notices
                    </strong>
                </p>
                <p>
                    Legal notices will be served to the email address you provide to iONclassic during the registration process. Notice will be deemed given 24 hours after email is
                    sent, unless the sending party is notified that the email address is invalid or that the email has not been delivered. Alternatively, we may give you legal notice
                    by mail to the address provided by you during the registration process. In such case, notice will be deemed given three days after the date of mailing. Any notices
                    to iONclassic must be given by registered U.S. Mail to iONclassic, LLC, ATTN: iONclassic, [ADDRESS].
                </p>
            </li>
            <li>
                <p>
                    <strong>
                        Governing Law; Disputes
                    </strong>
                </p>
                <p>
                    These Terms will be governed in all respects by the laws of the state of Florida, USA without regard to the internal law of Florida regarding conflicts of laws.
                    Other than as provided herein, with respect to any suit, action or proceeding relating hereto, each party hereby irrevocably submits to the exclusive, personal
                    jurisdiction of the courts of competent jurisdiction located in Hillsborough County, Florida, and waives any objection thereto. The prevailing party to any dispute
                    shall be entitled to reimbursement of its fees and costs from the other party. EACH PARTY WAIVES, TO THE FULLEST EXTENT PERMITTED BY LAW, ANY RIGHT IT MAY HAVE TO A
                    TRIAL BY JURY IN ANY ACTION ARISING HEREUNDER.
                </p>
                <p>
                    Alternatively, for any claim, iONclassic, in its sole discretion, may elect to resolve the dispute in a cost effective manner through binding, non-appearance-based
                    arbitration. If iONclassic elects arbitration, such arbitration will be initiated through an established alternative dispute resolution (“ADR”) provider, which is
                    to be mutually selected from a panel of ADR providers that iONclassic will provide to you. The ADR provider and the parties must comply with the following rules: (i)
                    he arbitration shall be conducted by telephone, online, or be solely based on written submissions; (ii) the arbitration shall not involve any personal appearance by
                    the parties or witnesses; and (iii) any judgment on the award rendered by the arbitrator may be entered in any court of competent jurisdiction.
                </p>
                <p>
                    All claims you bring against iONclassic must be resolved in accordance with these Terms. All claims filed or brought contrary to this Agreement shall be considered
                    improperly filed and a breach of these Terms. You shall not pursue any claims arising under this Agreement on a class action or other representative basis, and will
                    not seek to coordinate or consolidate any arbitration or action hereunder with any other proceeding. iONclassic’s failure to act with respect to a breach by you or
                    others does not waive our right to act with respect to subsequent or similar breaches.
                </p>
                <p>
                    Notwithstanding the foregoing, the parties are not required to arbitrate any dispute in which either party seeks equitable or other relief for: (1) the alleged
                    infringement of Intellectual Property Rights; or (ii) allegedly criminal conduct under laws of the United States or any state thereof.
                </p>
            </li>
            <li>
                <p>
                    <strong>
                        Privacy
                    </strong>
                </p>
                <p>
                    You acknowledge that when you download, install, or use the Platform, iONclassic may use automatic means (including, for example, cookies and web beacons) to collect
                    information about your device and about your use of the Platform. You also may be required to provide certain information about yourself as a condition to downloading,
                    installing, or using the Platform or certain of its features or functionality, and the Platform may provide you with opportunities to share information about yourself
                    with others. All information we collect through or in connection with the Platform, including without limitation your Registration Data and Payment Information, is subject
                    to our Privacy Policy at [LINK], which is incorporated herein and made a part hereof. If you object to your information being transferred or used in this way, then you must
                    not use the Platform. By downloading, installing, using, and providing information to or through the Platform, you consent to all actions taken by us with respect to your
                    information in compliance with the Privacy Policy.
                </p>
                <p>
                    iONclassic is the sole owner of the Personal Information collected through the Platform, including without limitation any information that you give us by email or by
                    direct contact with you. We use your information as described in the iONclassic Privacy Policy. If you object to your information being transferred or used in accordance
                    with the Terms herein or the terms of the Privacy Policy, then you must not use the Platform. For the avoidance of doubt, your name and some portion of your Personal
                    Information, as relevant, shall be used for identification purposes in the normal course of conducting business. This may include use on statements and any other
                    relevant access points where you conduct business on the Platform.  iONclassic may use your Personal Information to contact you regarding offers from our affiliates
                    from time to time, unless you opt out of such notifications. Otherwise, we will not sell or share your Personal Information with any third party other than as necessary
                    to provide you the Platform.
                </p>
            </li>
            <li>
                <p>
                    <strong>
                        General
                    </strong>
                </p>
                <p>
                    These Terms, including any addenda hereto, supersedes any other agreement between you and the Company and contains the entire understanding of the parties relating
                    to the subject matter hereof. The invalidity or unenforceability of any provision of these Terms shall not affect the validity or enforceability of any other provision.
                    Any invalid or unenforceable provision shall be deemed severed from these Terms to the extent of its invalidity or unenforceability, and the remainder of these Terms
                    shall otherwise remain in full force and effect. Our failure to enforce any part of this document is not a waiver of any of our rights to later enforce that or any
                    other part of this document. The waiver of any breach of any term, covenant, or condition herein contained, or our failure to seek redress for the violation of,
                    or to insist upon the strict performance of, any covenant or condition of these Terms shall not be deemed to be a waiver of such term, covenant or condition, or any
                    subsequent breach of the same. We may assign any of our rights and obligations under this document from time to time. Headings are for reference purposes only and in
                    no way define, limit, construe or describe the scope or extent of such section.
                </p>
            </li>
        </ol>
    </div>
  )
}

export default TermsConditions;
