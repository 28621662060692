import React, { Fragment } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';

import DashboardContainer from '../Dashboard/DashboardContainer';
import GetStartedContainer from '../GetStarted/GetStartedContainer';
import UpdateShopSubscription from '../GetStarted/UpdateShopSubscription';
import CreateCompanyContainer from '../CreateCompany/CreateCompanyContainer';
import ModalContainer from '../../components/shared/Modal/ModalContainer';
import { ModalContextProvider } from "../../services/Context/Context";
import NoMatch from './NoMatch';
import StartOwnerSubscription from '../OwnerSubscription/StartOwnerSubscription';
import UpdateOwnerSubscription from '../OwnerSubscription/UpdateownerSubscription';

const InternalRoutes = ({ history }) => {
  const { data } = useQuery(COMPANY_INFO);
  if (data && data.currentManager.subscriptionActive) {
  }
  // useEffect(() => {
  //   if (data && !data.currentManager.subscriptionActive) {
  //     history.push('/update-shop-subscription');
  //     console.log(data.currentManager.subscriptionActive)
  //   } else if (data && data.currentManager.subscriptionActive) {
  //     console.log(data.currentManager.subscriptionActive)
  //     history.push('/dashboard/restorations')
  //   }
  // }, [data, history])
  return(
    <Fragment>
      <div className='content'>
        <Switch>
          <Route path={'/dashboard'} render={(props) => <DashboardContainer {...props} children={props.children} />} />
          <Route exact path={'/get-started'} render={(props) => <GetStartedContainer {...props} />} />
          <Route exact path={'/update-shop-subscription'} render={(props) => <UpdateShopSubscription {...props} /> } />
          <Route exact path={'/start-subscription'} render={(props) => <StartOwnerSubscription {...props} />} />
          <Route exact path={'/update-subscription'} render={(props) => <UpdateOwnerSubscription {...props} /> } />
          <Route exact path={'/create-company'} render={(props) => <ModalContextProvider><ModalContainer /><CreateCompanyContainer {...props} /> </ModalContextProvider>} />
          <Route exact path={'/'} render={(props) => <Redirect to={'/dashboard/restorations'} {...props} children={props.children} />} />
          <Route path={'*'} render={(props) => <NoMatch {...props} children={props.children} /> } />
        </Switch>
      </div>

    </Fragment>
  )
}

export default InternalRoutes;

const COMPANY_INFO = gql`
  {
    currentManager {
      subscriptionActive
    }
  }
`;
