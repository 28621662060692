import React from 'react';

const DashboardNav = ({ history, activeTab, setActiveTab }) => {

  const onClickHandler = (tab, activeTab) => {
      if (tab !== activeTab) {
          setActiveTab(tab)
          history.push(`/dashboard/${tab}`)
      }
  }
  return(
    <div className="dashboard-nav">
      <div onClick={() => onClickHandler('restorations', activeTab)} className={`dashboard-nav-item ${activeTab === 'restorations' ? 'active': null}`}>COMPANY RESTORATIONS</div>
      <div onClick={() => onClickHandler('company', activeTab)} className={`dashboard-nav-item ${activeTab === 'company' ? 'active': null}`}>COMPANY PROFILE</div>
    </div>
  )
}

export default DashboardNav;
