import React from 'react';

import LandingButton from '../shared/buttons/LandingButton';

const LandingHeader = ({history, setModalState}) => {
  return(
    <div className='landing-header'>
      {/* <img className="hero-image" src={HeroImg} alt=''/> */}
      <div className="landing-header-content">
        {/* <div className='landing-header-content-icon'><img src={IonTreads} alt='ion treads' /></div> */}


      </div>
    </div>
  )
}

export default LandingHeader;
