import React from 'react';

import PasswordResetForm from './PasswordResetForm';

const PasswordReset = ({history, setAuthToken}) => {
  return(
    <div className="login-page">
      <PasswordResetForm history={history} setAuthToken={setAuthToken} />
    </div>
  )
}

export default PasswordReset;
