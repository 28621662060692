import React from 'react';
import MissingCarImage from './missing-car-main-transparent.svg';

function MissingCarMainTransparent({styles}) {
  return (
    <img src={MissingCarImage} alt="Add car profile" style={{width: "inherit", display: "block", margin: "auto", ...styles}} />
  )
}

export default MissingCarMainTransparent;
