import React, { useEffect, useContext, useState } from "react";
import { useQuery, useLazyQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";

import RestorationListItem from "./RestorationListItem";
import NoList from "./NoList";

import { MainContext } from "../../services/Context/Context";
import Button from "../shared/buttons/Button";
import MissingShopTransparent from "../../assets/images/fallback-images/missing-shop-transparent";

const RestorationsContainer = (props) => {
  const [refreshed, setRefreshed] = useState(false);
  const { dispatch } = useContext(MainContext);
  const { loading, error, data, refetch } = useQuery(CURRENT_MANAGER, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });
  const [fetchRestorations, restorationData] = useLazyQuery(CAR_LIST);
  // const [fetchOwnerName, updatedOwnerName] = useLazyQuery(CURRENT_MANAGER);

  useEffect(() => {
    // FETCH RESTORATIONS AFTER CHECKING CURRENT MANAGER
    // TO SEE IF THEY HAVE COMPLETED SIGNUP
    if (data && !loading) {
      if (!data.currentManager.shop) {
        if (refreshed) {
          props.history.push("/create-company");
        } else {
          refetch();
          setRefreshed(true);
        }
      } else {
        fetchRestorations();
        dispatch({
          type: "UPDATE_OWNER",
          payload: data.currentManager.shop.ownersName,
        });
      }
    }
  }, [data, loading, fetchRestorations, dispatch, props, refetch, refreshed]);
  // PLACEHOLDER INFO WHILE SECOND QUERY RETURNS
  if (restorationData.loading) return <p>Loading...</p>;
  if (restorationData.error) {
    return <p>Error :{console.log(restorationData.error)}</p>;
  }

  if (restorationData.data && !restorationData.loading) {
    restorationData.refetch();
    return (
      <div className="restorations-container">
        <div className="restorations-container-header">
          <Button
            text="ADD CAR"
            onClick={() =>
              props.history.push({
                pathname: "/dashboard/restorations/add-car/new",
                state: { id: restorationData.data.currentManager.shop.id },
              })
            }
          />
          <div className="shop-logo-container">
            {restorationData.data.currentManager.shop.profileImageUrl ? (
              <img
                src={
                  restorationData.data
                    ? restorationData.data.currentManager.shop.profileImageUrl
                    : null
                }
                alt="shop-logo"
              />
            ) : (
              <div className="shop-owner-image">
                <MissingShopTransparent />
              </div>
            )}
          </div>
        </div>
        <div className="restoration-list-container">
          {restorationData.data.currentManager.shop.restorations.length ? (
            restorationData.data.currentManager.shop.restorations.map(
              (restoration, index) => {
                return (
                  <div key={`restoration-${restoration.id}`}>
                    <RestorationListItem
                      id={restoration.id}
                      make={restoration.make}
                      model={restoration.model}
                      year={restoration.year}
                      miles={restoration.mileage}
                      qrCodeDocUrl={restoration.qrCodeDocUrl}
                      heroImage={restoration.heroImageUrl}
                      owner={restoration.owner}
                      history={props.history}
                    />
                  </div>
                );
              }
            )
          ) : (
            <div className="no-list-container">
              <div className="triangle"></div>
              <NoList />
            </div>
          )}
        </div>
      </div>
    );
  }
  // PLACEHOLDER INFO UNTIL FIRST QUERY RETURNS, UPDATE WITH SPINNER?
  if (loading) return <p>loading ...</p>;
  if (error) return <p>Error{console.log(error)} </p>;
  if (!data.currentManager.subscriptionActive) {
    props.history.push("/get-started");
  }
  if (data.currentManager.shop === null) {
    return <p>Loading end</p>;
  } else {
    return <p>Loading end</p>;
  }
};

export default RestorationsContainer;

const restorationFields = [
  "id, heroImageUrl",
  "make",
  "mileage",
  "year",
  "vin",
  "qrCodeDocUrl",
];

const CAR_LIST = gql`
  {
    currentManager {
      shop {
        id
        companyName
        ownersName
        profileImageUrl
        restorations {
          ${restorationFields.join(" ")}
          owner {
            firstName
            lastName
            email
            phoneNumbers {
              number
            }
          }
        }
      }
    }
  }
`;

const CURRENT_MANAGER = gql`
  {
    currentManager {
      subscriptionActive
      shop {
        ownersName
        companyName
      }
    }
  }
`;
