import React, { useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";

import RestorationDetailsForm from "./RestorationDetailsForm";
import ChevronLeft from "../../assets/images/ChevronLeft.png";

const AddCar = (props) => {
  const [createCarFromShop] = useMutation(CREATE_CAR);
  const [submitError, setSubmitError] = useState(false);
  const onSubmit = (values) => {
    createCarFromShop({
      variables: {
        input: {
          shopId: props.location.state.id,
          make: values.make,
          model: values.model,
          year: values.year,
          heroImageBase64: values.logo?.base64,
          vin: values.vin ? values.vin : "",
          chassis: values.chassis ? values.chassis : "",
          mileage: values.mileage ? parseInt(values.mileage) : 0,
          exteriorColor: values.exteriorColor ? values.exteriorColor : "",
          interiorColor: values.interiorColor ? values.interiorColor : "",
          driveType: values.driveType ? values.driveType : "",
          transmission: values.transmission ? values.transmission : "",
          bodyStyle: values.bodyStyle ? values.bodyStyle : "",
          engine: values.engine ? values.engine : "",
          fuel: values.fuel ? values.fuel : "",
        },
      },
    })
      .then(() => {
        props.history.push("/dashboard/restorations");
      })
      .catch((err) => {
        setSubmitError(err.message);
      });
  };

  const onCancel = () => {
    props.history.push("/dashboard/restorations");
  };

  return (
    <div className="restoration-details">
      <div className="restoration-details-header">
        <div
          className="restoration-details-header-chevron"
          onClick={() => onCancel()}
        >
          <img src={ChevronLeft} alt="" />{" "}
        </div>
        <div className="restoration-details-header-title">CAR DETAILS</div>
      </div>
      <RestorationDetailsForm
        onSubmit={onSubmit}
        onCancel={onCancel}
        carDetailsArray={carDetailsArray}
        newCar={true}
        submitError={submitError}
      />
    </div>
  );
};

export default AddCar;

const carDetailsArray = [
  "year",
  "make",
  "model",
  "vin",
  "chassis",
  "mileage",
  "exteriorColor",
  "interiorColor",
  "driveType",
  "transmission",
  "bodyStyle",
  "engine",
  "fuel",
];

const CREATE_CAR = gql`
  mutation createCarFromShop($input: CreateCarFromShopInput!) {
    createCarFromShop(input: $input) {
      clientMutationId
    }
  }
`;
