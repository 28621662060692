import React from 'react';
import { StripeProvider, Elements } from 'react-stripe-elements';
import { useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import StripeConstants from '../../constants/StripeConstants';

import StripeForm from './StripeForm';

const GetStartedContainer = ({ history, location, stripePublicKey }) => {
  const [createShopStripeSubscription, shopData] = useMutation(CREATE_SHOP_STRIPE_SUBSCRIPTION)

  return(
    <div className="get-started">
      <div className="get-started-header">SUBSCRIPTION INFO.</div>
      <div className="get-started-sub-info">
        <div className="get-started-sub-info-text">
          {location.state && location.state.userType !== 'owner' ?
            <ul>
              <li>$3.95 per car</li>
              <li>Unlimited mechanics</li>
              <li>Charged at the end of the month for each car</li>
            </ul>
          :
            <ul>
              <li>$9.95 per month</li>
            </ul>
          }
        </div>
      </div>
      <div className="get-started-header">ENTER PAYMENT METHOD</div>
      <div className="get-started-stripe">
        <StripeProvider apiKey={StripeConstants.STRIPE_KEY}>
          <Elements>
            <StripeForm
              history={history}
              stripeSubscription={createShopStripeSubscription}
              success={shopData.createShopStripeSubscription && shopData.createShopStripeSubscription.success ? shopData.createShopStripeSubscription.success : false}
              error={shopData && shopData.error ? true : false}
              loading={shopData && shopData.loading}
              shopOwner={location.state && location.state.userType !== 'owner' ? true : false}
            />
          </Elements>
        </StripeProvider>
      </div>
    </div>
  )
}

export default GetStartedContainer;

const CREATE_SHOP_STRIPE_SUBSCRIPTION = gql`
  mutation createShopStripeSubscription( $input: CreateShopStripeSubscriptionInput!) {
    createShopStripeSubscription(input: $input) {
      success
    }
  }
`;
