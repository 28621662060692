import React, { Component } from 'react';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  injectStripe
} from 'react-stripe-elements';

const createOptions = () => {

  return {
    style: {
      base: {
        fontSize: '14px',
        color: '#2699fb',
        letterSpacing: '0.025em',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#c23d4b',
      },
    },
  };
};

class _StripeForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage:'',
      stripeSuccess: false,
      apiCall: null,

      cardExpiryError: false,
      cardCvcError: false,
      cardNumberError: false,
      showErrors: false
    }
  }
 
  async handleSubmit(e) {
    e.preventDefault();
    if (this.state.cardCvcError && this.state.cardExpiryError && this.state.cardNumberError) {
      this.setState({showErrors: false})
      let {token} = await this.props.stripe.createToken({name: 'Name'});
      
      this.props.stripeSubscription({ variables: {
        input: {
          paymentId: token.id,
        }
      }}).then(response => {
        if (response.data) {
          this.setState({
            stripeSuccess: true,
          })
        }
      }).catch(err => {
        console.log(err);
        this.setState({
          errorMessage: err.message
        })
      })
    } else {
      this.setState({showErrors: true});
    }
  };

  successContinue = (e) => {
    e.preventDefault();
    this.props.history.push('/create-company')
  }

  handleChange = (e) => {
    if (e.complete) {
      this.setState({[e.elementType + 'Error']: true});
    } else {
      this.setState({[e.elementType + 'Error']: false});
    }
  }

  render() {
    return(
      <form onSubmit={this.handleSubmit.bind(this)}>
        <div className="stripe-input">
          <label>
            Card number
            <CardNumberElement
              {...createOptions()}
              onChange={this.handleChange}
            />
          </label>
          {this.state.showErrors && !this.state.cardNumberError ? <div className="error">CardNumber error</div> : null}
        </div>
        <div className="stripe-input">
          <label>
            Expiration date
            <CardExpiryElement
              {...createOptions()}
              onChange={this.handleChange}
            />
          </label>
          {this.state.showErrors && !this.state.cardExpiryError ? <div className="error">Card Expiry error</div> : null}
        </div>
        <div className="stripe-input">
          <label>
            CVC
            <CardCVCElement 
              {...createOptions()}
              onChange={this.handleChange}
            />
          </label>
          {this.state.showErrors && !this.state.cardCvcError ? <div className="error">Card Cvc error</div> : null}
        </div>
        <div className="stripe-input">
          <label>
            Postal code
            <input
              name="name"
              type="text"
              placeholder="94115"
              className="StripeElement"
              required
            />
          </label>
        </div>
        <div className="error" role="alert">
          {this.state.errorMessage ? 'There was an error processing your card': null }
        </div>
        <div className="stripe-success">
          {this.state.stripeSuccess ? 'Subscription Successful!' : null }
        </div>
        <div>
          {this.props.loading ?
          <div className="spinner">
            <div className="dot1"></div>
            <div className="dot2"></div>
          </div> : null}
        </div>

        <div className="company-profile-form-buttons">
          {/* CHECK THIS DIV TO SEE IF IT HAS SUBSCRIPTION DATA, IF IT DOES DISPLAY PROPER BUTTON */}
          {this.props.update ?
            <div className="company-profile-form-buttons-button" onClick={() => this.props.history.goBack(1)}>BACK</div>
            : null
            // <div className="company-profile-form-buttons-button" onClick={() => this.onCancel()}>CANCEL</div>
          }
          {this.state.stripeSuccess ?
            // conditional in here to select between next step and don't show
            this.props.history.location.pathname === '/update-shop-subscription' ?
              null : 
              <button onClick={(e) => this.successContinue(e)}>NEXT STEP</button>
             : <button>CONTINUE</button> 
          }
        </div>
      </form>
    )
  }
}

const StripeForm = injectStripe(_StripeForm);

export default StripeForm;
