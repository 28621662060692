import React, { useState, useContext } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";

import {ModalContext} from '../../services/Context/Context'
import ChevronLeft from "../../assets/images/ChevronLeft.png";

import RestorationDetailsForm from "./RestorationDetailsForm";

const RestorationDetails = (props) => {
  const [assignOwnerError, setAssignOwnerError] = useState(false);
  const [assignOwnerSuccess, setAssignOwnerSuccess] = useState(false);
  const [assignOwnerLoading, setAssignOwnerLoading] = useState(false);
  const { modalDispatch } = useContext(ModalContext);

  const { loading, error, data, refetch: refetchCarDetails } = useQuery(
    CAR_DETAILS,
    {
      variables: { id: props.match.params.id },
      fetchPolicy: "cache-and-network",
    }
  );
  const [updateCar, updateCarData] = useMutation(UPDATE_CAR, {
    notifyOnNetworkStatusChange: true,
  });

  const [updateCarImage] = useMutation(UPDATE_CAR, {
    notifyOnNetworkStatusChange: true,
    onCompleted: () => refetchCarDetails().then(() => {
      modalDispatch({
        type: 'OPEN_MODAL',
        payload: {
          component: 'ImageUploadedModal'
        }
      })
    })
  });

  const [deleteCarHeroImageMutation] = useMutation(DELETE_CAR_HERO_IMAGE, {
    onCompleted: refetchCarDetails,
  });
  const [assignOwnerToCar] = useMutation(ASSIGN_OWNER);

  const deleteCarHeroImage = () => {
    deleteCarHeroImageMutation({
      variables: {
        input: {
          carId: props.match.params.id,
        },
      },
    });
  };

  const onSubmit = (values) => {
    values.heroImageBase64 = values.logo?.base64;
    delete values.heroImageUrl;
    delete values.logo;
    delete values.owner;
    updateCar({
      variables: {
        input: {
          id: props.match.params.id,
          ...values,
        },
      },
    }).then(() => {
      props.history.push("/dashboard/restorations");
    });
  };

  const saveHeroImage = (e) => {
    e.persist();
    modalDispatch({
      type: "OPEN_MODAL",
      payload: {
        component: "UploadingImageModal",
      },
    });

    if (e.target.files.length === 0) {
      return;
    } else {
      // setFieldURL(URL.createObjectURL(e.target.files[0]));
    }
    let file = e.target.files[0];
    // Make new FileReader
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      let fileInfo = {
        name: file.name,
        type: file.type,
        size: Math.round(file.size / 1000) + " kB",
        base64: reader.result,
        file: file,
      };
      updateCarImage({
        variables: {
          input: {
            id: props.match.params.id,
            heroImageBase64: fileInfo.base64,
          },
        },
      });
    };
  };

  const assignOwner = (ownerEmail) => {
    setAssignOwnerLoading(true);
    let checkEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (checkEmail.test(ownerEmail)) {
      setAssignOwnerError(false);
      assignOwnerToCar({
        variables: {
          input: {
            email: ownerEmail,
            carId: props.match.params.id,
          },
        },
      }).then((response) => {
        // console.log(response); // THIS SHOULD BE SUCCESS, ON SUCCESS
        // SET ERROR TO FALSE OR SUCCESSFUL?
        setAssignOwnerLoading(false);
        setAssignOwnerSuccess(true);
      });
    } else {
      setAssignOwnerLoading(false);
      setAssignOwnerError(true);
    }
  };
  const onCancel = () => {
    props.history.push("/dashboard/restorations");
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p></p>;
  return (
    <div className="restoration-details">
      <div className="restoration-details-header">
        <div
          className="restoration-details-header-chevron"
          onClick={() => onCancel()}
        >
          <img src={ChevronLeft} alt="" />{" "}
        </div>
        <div className="restoration-details-header-title">CAR DETAILS</div>
      </div>
      <RestorationDetailsForm
        onSubmit={onSubmit}
        onCancel={onCancel}
        carDetails={{ ...data.car }}
        carDetailsArray={carDetailsArray}
        assignOwner={assignOwner}
        assignOwnerError={assignOwnerError}
        assignOwnerSuccess={assignOwnerSuccess}
        assignOwnerLoading={assignOwnerLoading}
        updateCarData={updateCarData}
        deleteCarHeroImage={deleteCarHeroImage}
        saveHeroImage={saveHeroImage}
      />
    </div>
  );
};

export default RestorationDetails;

const carDetailsArray = [
  "year",
  "make",
  "model",
  "vin",
  "chassis",
  "mileage",
  "exteriorColor",
  "interiorColor",
  "driveType",
  "transmission",
  "bodyStyle",
  "engine",
  "fuel",
];
const CAR_DETAILS = gql`
  query car( $id: ID!) {
    car(id: $id) {
      ${carDetailsArray.join(" ")}
      heroImageUrl
      #carDetailsArray is all of the details 
      #being returned by the query for a car,
      #Add more to the array to get more info
      owner {
        firstName
        lastName
        email
      }
    }
  }
`;

const UPDATE_CAR = gql`
  mutation updateCarFromShop($input: UpdateCarFromShopInput!) {
    updateCarFromShop(input: $input) {
      car {
        id
      }
    }
  }
`;

const DELETE_CAR_HERO_IMAGE = gql`
  mutation deleteCarHeroImageFromShop(
    $input: DeleteCarHeroImageFromShopInput!
  ) {
    deleteCarHeroImageFromShop(input: $input) {
      success
    }
  }
`;

const ASSIGN_OWNER = gql`
  mutation assignOwnerToCar($input: AssignOwnerToCarInput!) {
    assignOwnerToCar(input: $input) {
      success
    }
  }
`;
