import React from 'react';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';

const ServicesField = () => {
  const toggleService = (service, onChange) => {
    service.offered = !service.offered;
    onChange(service.offered);
  }
  return(
    <div className="service-container">
      <FieldArray name="shopServices">
        {({ fields }) => {
          return(fields.value.map((name, index) => (
            <div key={name.serviceId} className="service-container-item">
              <Field
                name={`${name.name}`}
                placeholder="First Name"
              >
              {({ input, meta}) => {
                  return(
                    <>
                      <label 
                        className={`service-container-button ${name.offered ? 'active' : null}`}
                        htmlFor={name.serviceId}
                        onClick={() => toggleService(name, input.onChange)}
                      >
                        {name.name}
                      </label>
                      <input 
                        id={name.serviceId}
                        name={name.name}
                        type="hidden"
                      />
                    </>
                  )
                }}
              </Field>
            </div>
          ))
          )
        }}
      </FieldArray>
    </div>
  )
}

export default ServicesField;
