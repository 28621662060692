import React from 'react';

const NavBarButton = ({ text, onClick }) => {
  return(
    <div className="navbar-button" onClick={onClick}>
      {text}
    </div>
  )
};

export default NavBarButton;
