import React from 'react';

import PlusSymbol from '../../assets/images/PlusSymbol.png';

const CompanyProfileMechanics = ({ mechanics, history }) => {
  return(
    <div className="company-profile-mechanics">
      <div className="company-profile-mechanics-header">
        <div className="company-profile-mechanics-header-title">Your Mechanics</div>
        <div className="company-profile-mechanics-header-icon" onClick={() => history.push('/dashboard/invite')}>
          <img src={PlusSymbol} alt='invite new mechanic' />
          <div>INVITE MORE</div>
        </div>
      </div>
      <div className="company-profile-mechanics-container">
        {mechanics.map( mechanic => (
          <>
            <div className="company-profile-mechanics-container-info">
              <div className="company-profile-mechanics-container-info-name">{`${mechanic.firstName} ${mechanic.lastName}`}</div>
              <div className="company-profile-mechanics-container-info-phone">{mechanic.email}</div>
            </div>
            <div className="company-profile-mechanics-container-info-accepted">
              accepted
            </div>
          </>
        ))}
      </div>
    </div>
  )
}

export default CompanyProfileMechanics;
