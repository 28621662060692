import React from "react";
import Button from "../shared/buttons/Button";
import CheckIcon from "./../../assets/images/icons/check";

const InviteModal = ({ onClose, modalData: { onSubmit } }) => {
  return (
    <div className="invite-modal">
      <div className="check-icon">
        <CheckIcon />
      </div>
      <div className="invite-modal-header header">Invitation Sent</div>
      <div className="button-row one-button">
        <Button
          text="Return to Company Profile"
          onClick={() => {
            onSubmit();
            onClose();
          }}
        />
      </div>
    </div>
  );
};

export default InviteModal;
