import React, { Component } from 'react';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  injectStripe
} from 'react-stripe-elements';

const createOptions = () => {

  return {
    style: {
      base: {
        fontSize: '14px',
        color: '#2699fb',
        letterSpacing: '0.025em',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#c23d4b',
      },
    },
  };
};

class _OwnerStripeForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage:'',
      stripeSuccess: false,
      apiCall: null,

      cardExpiryError: false,
      cardCvcError: false,
      cardNumberError: false,
      showErrors: false
    }
  }

  async handleSubmit(e) {
    e.preventDefault();
    if (this.state.cardCvcError && this.state.cardExpiryError && this.state.cardNumberError) {
      this.setState({showErrors: false})
      let {token} = await this.props.stripe.createToken({name: 'Name'});
      
      this.props.createStripeSubscription({ variables: {
        input: {
          paymentId: token.id,
        }
      }}).then(response => {
        if (response.data) {
          this.setState({
            stripeSuccess: true,
          })
        }
      }).catch(err => {
        this.setState({
          errorMessage: err.message
        })
      })
    } else {
      this.setState({showErrors: true});
    }
  };

  handleChange = (e) => {
    if (e.complete) {
      this.setState({[e.elementType + 'Error']: true});
    } else {
      this.setState({[e.elementType + 'Error']: false});
    }
  }

  onCancel = (e) => {
    e.preventDefault()
    // CANCEL PAYMENT OF 
    this.props.cancelStripeSubscription();
    //set value in here to change cancel succes state
    // this.props.
  }

  render() {
    return(
      <div>
        <form onSubmit={this.handleSubmit.bind(this)}>
          <div className="stripe-input">
            <label>
              Card number
              <CardNumberElement
                {...createOptions()}
                onChange={this.handleChange}
                disabled={this.state.stripeSuccess ? true : false}
                required
              />
            </label>
            {this.state.showErrors && !this.state.cardNumberError ? <div className="error">CardNumber error</div> : null}
          </div>
          <div className="stripe-input">
            <label>
              Expiration date
              <CardExpiryElement
                {...createOptions()}
                onChange={this.handleChange}
                disabled={this.state.stripeSuccess ? true : false}
              />
            </label>
            {this.state.showErrors && !this.state.cardExpiryError ? <div className="error">Card Expiry error</div> : null}
          </div>
          <div className="stripe-input">
            <label>
              CVC
              <CardCVCElement 
                {...createOptions()}
                onChange={this.handleChange}
                disabled={this.state.stripeSuccess ? true : false}
              />
            </label>
            {this.state.showErrors && !this.state.cardCvcError ? <div className="error">Card Cvc error</div> : null}
          </div>
          <div className="stripe-input">
            <label>
              Postal code
              <input
                name="name"
                type="text"
                placeholder="94115"
                className="StripeElement"
                required
              />
            </label>
          </div>
          <div className="error" role="alert">
            {this.state.errorMessage ? 'There was an error processing your card': null }
          </div>
          <div className="stripe-success">
            {this.state.stripeSuccess ? 'Subscription Successful!' : null }
          </div>
          <div>
            {this.props.loading ? 
            <div className="spinner">
              <div className="dot1"></div>
              <div className="dot2"></div>
            </div> : null}
          </div>

          <div className="company-profile-form-buttons">
            {this.props.location.pathname === '/start-subscription' ? null
              : 
              this.props.subscriptionActive ?
                <div className="company-profile-form-buttons-button" onClick={(e) => this.onCancel(e)} >CANCEL SUBSCRIPTION</div>
                : null
            }
            {console.log(this.props.location)}
              {this.state.stripeSuccess ?  
                null
                : <button>{this.props.location === "/update-subscription" ? "UPDATE" : "CONTINUE"}</button> }
          </div>
        </form>
      </div>
    )
  }
}

const OwnerStripeForm = injectStripe(_OwnerStripeForm);

export default OwnerStripeForm;
