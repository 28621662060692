import React from 'react';

import LandingHeader from './LandingHeader';

import ShopPasswordReset from "./ShopPasswordReset/ShopPasswordReset";

const ShopPasswordResetPageContainer = ({ history, modalState, setModalState, setAuthToken }) => {
  return(
    <div className="landing-owner">
      <LandingHeader history={history} setModalState={setModalState} />
      <ShopPasswordReset history={history} setModalState={setModalState} modalState={modalState} setAuthToken={setAuthToken} />
    </div>
  )
}

export default ShopPasswordResetPageContainer;
