import React, { Component } from "react";
import Carousel, {} from "@brainhubeu/react-carousel";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import "@brainhubeu/react-carousel/lib/style.css";
import AddCarIcon from "../../assets/images/icons/add-car-icon";
import RedDeleteIcon from "./../../assets/images/icons/red-delete-icon";
import LoadingSpinner from "./../../assets/images/icons/loading-spinner";

class AddPhoto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      photoIndex: 0,
      isOpen: false,
    };
  }
  openLightbox = (index) => {
    this.setState({ photoIndex: index, isOpen: true });
  };

  render() {
    const { photoIndex, isOpen } = this.state;
    const settings = {
      dots: true,
      slidesToShow: 3,
      infinite: this.props.images && this.props.images.length > 3,
    };

    return (
      <div className="add-photo">
        <>
          <div className="add-car-button">
            <label htmlFor={`photos-${this.props.category}`}>
              <AddCarIcon />
            </label>
            <input
              id={`photos-${this.props.category}`}
              className="add-photo-input"
              type="file"
              // onChange={(e) => this.props.fieldChange(e, this.props.category)}
              onChange={(e) => this.props.saveImages(e, this.props.category)}
              multiple={true}
            />
          </div>
          <Carousel
            arrows
            arrowLeft={() => <button />}
            arrowRight={() => <button />}
            addArrowClickHandler
            infinite={settings.infinite}
            draggable={false}
            offset={20}
            itemWidth={200}
          >
            {!this.props.imagesLoading ? (
              <div className="car-image-loading-spinner">
                <LoadingSpinner />
              </div>
            ) : (
              this.props.images.map((image, index) => (
                <div key={index}>
                  {this.props.internalImagesDeleting?.find(
                    (i) => i.id === image.id
                  )?.deleting ? (
                    <div className="car-image-loading-spinner">
                      <LoadingSpinner />
                    </div>
                  ) : (
                    <>
                      <div
                        onClick={(e) => {
                          // this.props.deleteImageFromShop(image.id)
                          e.preventDefault();
                          this.props.modalDispatch({
                            type: "OPEN_MODAL",
                            payload: {
                              component: "DeleteImageModal",
                              onSubmit: () =>
                                this.props.deleteImageFromShop(image.id),
                            },
                          });
                        }}
                        className="delete-car-image"
                      >
                        <RedDeleteIcon />
                      </div>
                      <img
                        src={image.imageUrl}
                        key={index}
                        onClick={() => this.openLightbox(index)}
                        width={200}
                        alt="Car"
                      />
                    </>
                  )}
                </div>
              ))
            )}
          </Carousel>
        </>
        {isOpen && (
          <Lightbox
            mainSrc={this.props.images[photoIndex].imageUrl}
            wrapperClassName="light-box-modal"
            nextSrc={
              this.props.images[(photoIndex + 1) % this.props.images.length]
                .imageUrl
            }
            prevSrc={
              this.props.images[
                (photoIndex + this.props.images.length - 1) %
                  this.props.images.length
              ].imageUrl
            }
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex:
                  (photoIndex + this.props.images.length - 1) %
                  this.props.images.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % this.props.images.length,
              })
            }
          />
        )}
      </div>
    );
  }
}

export default AddPhoto;

const CarouselChild = ({
  images,
  internalImagesDeleting,
  modalDispatch,
  deleteImageFromShop,
  openLightbox,
}) => {
  return !this.props.imagesLoading ? (
    <div className="car-image-loading-spinner">
      <LoadingSpinner />
    </div>
  ) : (
    this.props.images.map((image, index) => (
      <div key={index}>
        {this.props.internalImagesDeleting?.find((i) => i.id === image.id)
          ?.deleting ? (
          <div className="car-image-loading-spinner">
            <LoadingSpinner />
            ..
          </div>
        ) : (
          <>
            <div
              onClick={(e) => {
                // this.props.deleteImageFromShop(image.id)
                e.preventDefault();
                this.props.modalDispatch({
                  type: "OPEN_MODAL",
                  payload: {
                    component: "DeleteImageModal",
                    onSubmit: () => this.props.deleteImageFromShop(image.id),
                  },
                });
              }}
              className="delete-car-image"
            >
              <RedDeleteIcon />
            </div>
            <img
              src={image.imageUrl}
              key={index}
              onClick={() => this.openLightbox(index)}
              width={200}
            />
          </>
        )}
      </div>
    ))
  );
};
