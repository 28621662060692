import React from 'react'

const NoList = () => {
  return(
    <div className="no-list">
      <div className="no-list-header">ADD A CAR</div>
      <div className="no-list-text">
        Get started by adding your first car
        by clicking the ADD CAR button above
      </div>
    </div>
  )
}

export default NoList;
