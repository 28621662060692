import React, { useContext, useEffect } from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';

import './styles.scss';
import { getModalComponent } from '../../../helpers/getModalComponent';
import { ModalContext } from '../../../services/Context/Context';

import ModalBody from './Modal';

const ModalContainer = () => {
  const { modalDispatch, modalState: { modalOpen, modalMetaData } } = useContext(ModalContext);
  // const [open, setOpen] = useState(false);

  const closeModal = () => {
    modalDispatch({
      type: 'CLOSE_MODAL',
    });
  };

  useEffect(() => Modal.setAppElement('body'), []);

  return (
    <div className="modal-container">
      <Modal
        isOpen={modalOpen}
        onRequestClose={closeModal}
        portalClassName="IonDefaultModal"
      >
        <ModalBody
          component={getModalComponent(modalMetaData, closeModal)}
          onClose={closeModal}
          metadata={modalMetaData}
        />
      </Modal>
    </div>
  );
};

export default ModalContainer;

ModalContainer.propTypes = {
  open: PropTypes.bool,
  metadata: PropTypes.object,
};
