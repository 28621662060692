import React, { useEffect, useContext } from 'react';
import { StripeProvider, Elements } from 'react-stripe-elements';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';

import OwnerStripeForm from './OwnerStripeForm';

import { MainContext } from '../../services/Context/Context';
import * as StripeConstants from "../../constants/StripeConstants";

const StartOwnerSubscription = (props) => {
  const { dispatch } = useContext(MainContext);
  const [createStripeSubscription, data] = useMutation(CREATE_STRIPE_SUBSCRIPTION);
  const { loading, data: userData } = useQuery(CURRENT_USER, {
    notifyOnNetworkStatusChange: true,
  });
  useEffect(() => {
    if (userData && !loading) {
      dispatch({ type: "UPDATE_OWNER", payload: userData.currentUser.firstName})
      if (userData.currentUser.subscriptionActive) {
        props.history.push('/update-subscription')
      }
    }

  }, [userData, dispatch, loading, props.history]);

  return(
    <div className="owner-subscription">
      <div className="owner-subscription-header">SUBSCRIPTION INFO</div>

      <div className="owner-subscription-sub-info">
        <div className="owner-subscription-sub-info-text">
            <ul>
              <li>$4.95 per car</li>
              <li>Charged at the end of the month for each car</li>
            </ul>
        </div>
      </div>

      <div className="owner-subscription-header">ENTER PAYMENT INFORMATION</div>

      <div className="owner-subscription-stripe">
        <StripeProvider apiKey={StripeConstants.STRIPE_KEY}>
          <Elements>
            <OwnerStripeForm
              createStripeSubscription={createStripeSubscription}
              loading={data && data.loading}
              location={props.history.location.pathname}
              subscriptionActive={userData && userData.currentUser.subscriptionActive}
            />
          </Elements>
        </StripeProvider>
      </div>
    </div>
  )
}

export default StartOwnerSubscription;

const CREATE_STRIPE_SUBSCRIPTION = gql`
  mutation createStripeSubscription( $input: CreateStripeSubscriptionInput!) {
    createStripeSubscription(input: $input) {
      success
    }
  }
`;

const CURRENT_USER = gql`
  {
    currentUser {
      firstName
      subscriptionActive
    }
  }
`;
