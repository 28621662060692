import React from 'react';

import ShopLoginForm from './ShopLoginForm';

const ShopLogin = ({history, modalState, setModalState, setAuthToken}) => {
  return(
    <div className="login-page">
      <ShopLoginForm history={history} setModalState={setModalState} modalState={modalState} setAuthToken={setAuthToken} />
    </div>
  )
}

export default ShopLogin;
