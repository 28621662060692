import request from 'superagent';
import ApiConstants from '../constants/ApiConstants';
require('superagent-auth-bearer')(request);

function getUrlForPath(path) {
  return `${ApiConstants.API_URL}${path}`;
}

export var Api = {
  get(path, authToken = '') {
    return new Promise(function(resolve, reject) {
      request
        .get(getUrlForPath(path))
        .set('token', authToken)
        .set('Accept','application/json')
        .end(function(error, response) {
          if (error) {
            reject(error);
          } else {
            resolve(response.body);
          }
        });
    });
  },
  post(path, data, authToken = null, setState) {
		return new Promise((resolve, reject) => {
			request
        .post(getUrlForPath(path))
				.set('Content-Type','application/json')
        .set('token', authToken)
				.send(data)
				.end(function(error, response) {
					if (error) {
            if (setState) {
              setState(error.status);
            }
						reject(error);
					} else {
						resolve(response.body);
					}
				});
		});
  },
  put(path, data, authToken = null) {
		return new Promise(function(resolve, reject) {
			request
				.put(getUrlForPath(path))
				.authBearer(authToken)
				.type('application/json')
				.send(data)
				.end(function(error, response) {
					if (error) {
						reject(response.body.errors);
					} else {
						resolve(response.body);
					}
				});
		});
  },
  delete(path, authToken = null) {
		return new Promise(function(resolve, reject) {
			request
				.delete(getUrlForPath(path))
				.authBearer(authToken)
				.type('application/json')
				.end(function(error, response) {
					if (error) {
						reject(response.body.errors);
					} else {
						resolve(response.body);
					}
				});
		});
	}
}
