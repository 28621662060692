import React, { useState, useContext } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";

import SliderContainer from "./SliderContainer";
import ChevronLeft from "../../assets/images/ChevronLeft.png";
import { ModalContext } from "../../services/Context/Context";
import { toTitleCase } from "./../../helpers/text";

const Photos = (props) => {
  const { modalDispatch } = useContext(ModalContext);
  let carId = props.match.params.id;

  const { data: carImageCategories } = useQuery(CAR_IMAGE_CATEGORIES);

  const { loading, error, data, refetch } = useQuery(CAR, {
    variables: {
      id: props.match.params.id,
      carId: props.match.params.id,
      image_size: "original",
    },
    fetchPolicy: "network-only",
  });
  const [addImageFromShop] = useMutation(ADD_IMAGE, {
    onCompleted: (data) => {
      setInternalImages([]);
      refetch().then(() => {
        modalDispatch({
          type: "OPEN_MODAL",
          payload: {
            component: "ImageUploadedAddModal",
            onSubmit: fieldChangeFunc,
            categoryId: data.addImageFromShop?.carImage?.carImageCategoryId,
          },
        });
      });
    },
  });
  const [internalImages, setInternalImages] = useState(data || []);
  const imagesLoading = (categoryId) => {
    return internalImages?.find((c) => c.id == categoryId)?.loading;
  };

  const chevronClick = () => {
    props.history.push("/dashboard/restorations");
  };

  let fieldChangeFunc = (e, category, title) => {
    modalDispatch({
      type: "OPEN_MODAL",
      payload: {
        component: "UploadingImageModal",
      },
    });
    if (e.target.files.length === 0) {
      return;
    }

    const internalImagesStatuses = Object.assign(
      [],
      data.car.carImageCategories
    );
    internalImagesStatuses.find((c) => c.id == category).loading = true;
    setInternalImages(internalImagesStatuses);

    let files = e.target.files;
    // Process each file
    for (var i = 0; i < files.length; i++) {
      let file = files[i];
      // Make new FileReader
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let fileInfo = {
          name: file.name,
          type: file.type,
          size: Math.round(file.size / 1000) + " kB",
          base64: reader.result,
          file: file,
        };
        addImageFromShop({
          variables: {
            input: {
              carId: props.match.params.id,
              imageBase64: fileInfo.base64,
              carImageCategoryId: category,
            },
          },
        });
      };
    }
  };

  if (loading) return <div>loading</div>;
  if (error) return <div>error</div>;
  if (data)
    return (
      <div className="photos">
        <div className="photos-header">
          <div className="photos-header-chevron" onClick={() => chevronClick()}>
            <img src={ChevronLeft} alt="" />{" "}
          </div>
          <div className="photos-header-title">PHOTOS</div>
        </div>
        <div className="photos-body">
          <div
            className="photos-body-header"
            onClick={() =>
              props.history.push(`/dashboard/restorations/${carId}`)
            }
          >
            {data.car.make} {data.car.model} {data.car.year}
          </div>
          {carImageCategories?.carImageCategories.map((category) => {
            return (
              <SliderContainer
                title={toTitleCase(category.name)}
                fieldChange={fieldChangeFunc}
                category={category.id}
                carId={carId}
                images={
                  data.car.carImageCategories.filter(
                    (c) => c.id === category.id
                  )[0].images
                }
                refetch={refetch}
                imagesLoading={imagesLoading(category.id)}
                key={`slider-${category.id}`}
              />
            );
          })}
        </div>
      </div>
    );
};

export default Photos;

const ADD_IMAGE = gql`
  mutation addImageFromShop($input: AddCarImageFromShopInput!) {
    addImageFromShop(input: $input) {
      carImage {
        imageUrl
        carImageCategoryId
      }
    }
  }
`;

const CAR = gql`
  query car($id: ID!, $carId: ID!) {
    car(id: $id) {
      make
      model
      year
      carImageCategories {
        id
        images(carId: $carId) {
          id
          imageUrl
        }
      }
    }
  }
`;

const CAR_IMAGE_CATEGORIES = gql`
  query carImageCategories {
    carImageCategories {
      id
      name
    }
  }
`;
