import React from 'react';
import DeleteImageModal from '../components/shared/Modal/DeleteImageModal';
import UploadingImageModal from '../components/shared/Modal/UploadingImageModal';
import ImageUploadedAddModal from '../components/shared/Modal/ImageUploadedAddModal';
import ImageUploadedModal from '../components/shared/Modal/ImageUploadedModal';
import InviteModal from '../components/Invite/InviteModal';

const modalTypeToComponent = {
  DeleteImageModal,
  UploadingImageModal,
  ImageUploadedModal,
  ImageUploadedAddModal,
  InviteModal,
};

/* eslint-disable import/prefer-default-export */
export const getModalComponent = (metadata, onClose) => {
  const ModalComponent = modalTypeToComponent[metadata.component];

  return ModalComponent ? (
    <ModalComponent modalData={metadata} onClose={onClose} />
  ) : (
    <div>There is no modal found with name {metadata.component}</div>
  );
};
