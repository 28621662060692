import React, { useEffect, useContext } from 'react';
import { StripeProvider, Elements } from 'react-stripe-elements';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';

import OwnerStripeForm from './OwnerStripeForm';

import { MainContext } from '../../services/Context/Context';
import StripeConstants from "../../constants/StripeConstants";

const UpdateOwnerSubscription = (props) => {
  const { dispatch } = useContext(MainContext);
  const [updateStripeSubscription, data] = useMutation(UPDATE_STRIPE_SUBSCRIPTION);
  const [cancelStripeSubscription, cancelResponse] = useMutation(CANCEL_STRIPE_SUBSCRIPTION);
  const { loading, data: userData } = useQuery(CURRENT_USER, {
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (userData && !loading) {
      dispatch({ type: "UPDATE_OWNER", payload: userData.currentUser.firstName })
    }
  }, [userData, dispatch, loading]);

  return(
    <div className="owner-subscription">
      <div className="owner-subscription-header">SUBSCRIPTION INFO</div>

      <div className="owner-subscription-sub-info">
        <div className="owner-subscription-sub-info-text">
            <ul>
              <li>$4.95 per car</li>
              <li>Charged at the end of the month for each car</li>
            </ul>
        </div>
      </div>

      <div className="owner-subscription-header">UPDATE PAYMENT INFORMATION</div>

      <div className="owner-subscription-stripe">
        <StripeProvider apiKey={StripeConstants.STRIPE_KEY}>
          <Elements>
            <OwnerStripeForm
              createStripeSubscription={updateStripeSubscription}
              cancelStripeSubscription={cancelStripeSubscription}
              loading={data && data.loading}
              location={props.history.location.pathname}
              subscriptionActive={userData && userData.currentUser.subscriptionActive}
            />
          </Elements>
        </StripeProvider>
      </div>
      <div>
        {cancelResponse.called ? <div className="cancel-success">Cancel Success</div> : null}
      </div>
    </div>
  )
}

export default UpdateOwnerSubscription;

const UPDATE_STRIPE_SUBSCRIPTION = gql`
  mutation updateStripeSubscription( $input: UpdateStripeSubscriptionInput!) {
    updateStripeSubscription(input: $input) {
      success
    }
  }
`;

const CURRENT_USER = gql`
  {
    currentUser {
      firstName
      subscriptionActive
    }
  }
`;

const CANCEL_STRIPE_SUBSCRIPTION = gql`
  mutation cancelStripeSubscription {
    cancelStripeSubscription {
      success
    }
  }
`
