import React, { useState } from "react";
import { Switch, Route, useHistory } from "react-router-dom";

import DashboardNav from "./DashboardNav";
import RestorationsContainer from "../Restorations/RestorationsContainer";
import CompanyProfileContainer from "../CompanyProfile/CompanyProfileContainer";
import InviteContainer from "../Invite/InviteContainer";
import RestorationDetails from "../Restorations/RestorationDetails";
import AddCar from "../Restorations/AddCar";
import Photos from "../Photos/Photos";
import SlideShow from "../SlideShow/SlideShow";
import { ModalContextProvider } from "../../services/Context/Context";
import ModalContainer from "../shared/Modal/ModalContainer";

const DashboardContainer = (props) => {
  const [activeTab, setActiveTab] = useState(
    window.location.pathname === "/dashboard/restorations"
    ? "restorations"
      : "company"
  );

  if (props.location.pathname === "/dashboard") {
    props.history.push("/dashboard/restorations");
  }
  return (
    <div className="dashboard">
      <DashboardNav
        history={props.history}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      <Switch>
        <ModalContextProvider>
          <ModalContainer />
          <Route
            exact
            path={`${props.match.url}/restorations/add-car/new`}
            render={(props) => <AddCar {...props} />}
          />
          <Route
            exact
            path={`${props.match.url}/restorations`}
            render={(props) => <RestorationsContainer {...props} />}
          />
          <Route
            exact
            path={`${props.match.url}/restorations/:id?`}
            render={(props) => <RestorationDetails {...props} />}
          />
          <Route
            exact
            path={`${props.match.url}/restorations/photos/:id`}
            render={(props) => <Photos {...props} />}
          />
          <Route
            exact
            path={`${props.match.url}/restorations/slide-show/:id`}
            render={(props) => <SlideShow {...props} />}
          />
          <Route
            path={`${props.match.url}/company`}
            render={(props) => (
              <CompanyProfileContainer
                {...props}
                history={props.history}
                setActiveTab={setActiveTab}
              />
            )}
          />
          <Route
            path={`${props.match.url}/invite`}
            render={(props) => <InviteContainer {...props} />}
          />
        </ModalContextProvider>
      </Switch>
    </div>
  );
};

export default DashboardContainer;
