import React from 'react';
import AddIcon from './green-add-icon.svg'

function GreenAddIcon({styles}) {
  return (
    <img src={AddIcon} alt="Add icon" style={{...styles}} />
  )
}

export default GreenAddIcon;
