import React, { useContext } from 'react';
// import { Link } from 'react-router-dom';

import NavBarButton from '../shared/buttons/NavBarButton';

import LogoMain from '../../assets/images/logo-main3.svg';
import { MainContext } from '../../services/Context/Context';


const NavBar = ({ loginModal, authToken, setAuthToken, location, history}) => {
  const { state, dispatch } = useContext(MainContext);
  // const noLoginPaths = ['/privacy-policy', '/terms-and-conditions', '/end-user-agreement']
  const logout = () => {
    dispatch({ type: "UPDATE_OWNER", payload: ''})
    localStorage.setItem('ionToken', '');
    setAuthToken(false);
    window.location.replace('https://ionclassic.com')
  }
  return(
    <div className='navbar'>
      <div className='navbar-banner'>
        <img src={LogoMain} alt='Ion Logo' />
      </div>
      <div className={`navbar-items ${authToken ? 'signed-in': ''}`}>
        {authToken &&
            <div className="signed-in">
              <NavBarButton text={'LOG OUT'} onClick={() => logout()} />
              <div className="signed-in">Hello {state.ownerName}</div>
            </div>
        }

      </div>
    </div>
  )
}

export default NavBar;
