import React from 'react';
import { Field } from 'react-final-form';
import Select from 'react-select';
import { UsaStates } from 'usa-states';

let usStates = new UsaStates({ includeTerritories: false});
let stateList = usStates.arrayOf('abbreviations');
let abbStateList = stateList.map(state => {
  return { value: state, label: state }
});

const CompanyProfileFormField = ({ name, label, validate, select, ownerInput, assignOwner, style }) => {
  const keyPress = e => {
    if (e.key === 'Enter') {
      e.preventDefault();
      assignOwner(e.target.value);
    }
  }
  return(
    <Field
      name={name}
      validate={validate ? validate : null}
      >
      {({ input, meta }) => (
        <div className='company-profile-form-col-input' style={style}>
          {input.value ? <label className='company-profile-form-input-label'>{label}</label> : null}
          {select ? 
            <Select 
              name='name'
              value={{...input.value}}
              placeholder={label}
              onChange={input.onChange}
              options={abbStateList}
              styles={selectStyles}
            /> 
            :
            <input {...input} type='text' placeholder={label} onKeyPress={ownerInput ? keyPress : null}/> }
          {meta.error && meta.touched && <div className='company-profile-form-col-input-error'>{meta.error}</div>}
        </div>
      )}
    </Field>
  )
}

export default CompanyProfileFormField;

const selectStyles = {
  container: (styles, state) => ({
    ...styles,
    marginTop: 5,
  }),
  control: (styles, state) => ({
    ...styles,
    padding: '5px 0px',
    border: 'solid 1px #bce0fd',
    borderRadius: 4,
    transform: 'translateY(-5px)',
    '&:hover': {
      cursor: 'pointer',
    }
  }),
  singleValue: (styles) => ({
    color: '#2699fb',
    fontSize: '14px',
  }),
  placeholder: (styles) => ({
    ...styles,
    fontSize: '14px',
    transform: 'translateY(-7px)'
  })
}