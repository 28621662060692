import React from 'react';
import Spinner from './loading-spinner.svg'
import './styles.scss'

function LoadingSpinner({styles}) {
  return (
    <img id="spinner" src={Spinner} alt="Loading spinner" style={{width: "inherit", height: "inherit"}}/>
  )
}

export default LoadingSpinner;