import React from 'react';
import MissingShopImage from './missing-shop-transparent.svg'

function MissingShopTransparent({styles}) {
  return (
    <img src={MissingShopImage} alt="Shop Owner" style={{width: "inherit", display: "block", margin: "auto", ...styles}} />
  )
}

export default MissingShopTransparent;
