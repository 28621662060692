import React from 'react';
import DeleteIcon from './red-delete-icon.svg'

function RedDeleteIcon({styles}) {
  return (
    <img src={DeleteIcon} alt="Add icon" styles={{...styles}} />
  )
}

export default RedDeleteIcon;
